import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';
import CategorySegment from '../../components/Segment/CategorySegment';
import CourseSegment from '../../components/Segment/CourseSegment';
import './style.css';

const Path = ({ user, getCourseDetails, courseLoading }) => {
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (user) {
      fetch('/api/v1/courses')
        .then((response) => response.json())
        .then((response) => setCategories(response));
    }
  }, [courseLoading]);
  return (
    <div className='path'>
      <Divider horizontal>{t('admin.LearningPath')}</Divider>
      <br />
      {categories.map((category) => {
        if (category.isInternal || category.courses.length === 1) {
          return category.courses.map((course) => (
            <CourseSegment
              key={`course-${course.id}`}
              user={user}
              course={course}
              courseLoading={courseLoading}
              getCourseDetails={getCourseDetails}
            />
          ));
        }
        return (
          <CategorySegment
            key={`category-${category.id}`}
            user={user}
            category={category}
            courseLoading={courseLoading}
            getCourseDetails={getCourseDetails}
          />
        );
      })}
    </div>
  );
};

export default Path;
