import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Radio,
  Segment,
} from 'semantic-ui-react';

import './style.css';

const LandingForm = () => {
  const [contactOpen, setContactOpen] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestSent, setRequestSent] = React.useState(false);
  const [request, setRequest] = React.useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
  });
  const [isManager, setIsManager] = React.useState(null);
  const [emailAccepted, setEmailAccepted] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const { t } = useTranslation();
  const handleChange = (e, { name, value }) => {
    if (name) {
      setRequest(({ ...emailContent }) => {
        emailContent[name] = value;
        return emailContent;
      });
    }
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setRequestLoading(true);
    if (isManager) {
      request.isManager = true;
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    };
    fetch('/api/v1/demo', requestOptions).then(() => {
      setRequestLoading(false);
      setRequestSent(true);
    });
  };

  const buttonEnabled = () => {
    return (
      termsAccepted &&
      emailAccepted &&
      request.email.length > 0 &&
      request.firstName.length > 0 &&
      request.lastName.length > 0 &&
      (!isManager || isManager === false || request.company.length > 0)
    );
  };

  return (
    <div className='landing__register'>
      <div className='ui stackable grid container centered'>
        {!requestSent && (
          <div className='column ten wide'>
            <button
              className='bold-text landing__button'
              onClick={() => setContactOpen(!contactOpen)}
            >
              <div className='landing__blue'>
                <span />
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('landingPage.userRegBtn'),
                  }}
                />
                <img alt='Skillaby' src='skillaby_S_wo.png' />
              </div>
            </button>
          </div>
        )}
        {contactOpen && !requestSent && (
          <div className='column ten wide'>
            <Form className='landing__form' onSubmit={handleSubmit} inverted>
              <Container textAlign='center'>
                <h2 className='white-text'>{t('landingPage.userRegTitle')}</h2>
              </Container>
              <Segment placeholder>
                <Grid columns={2} stackable textAlign='center'>
                  <Divider vertical className='hide-mobile'>
                    {t('landingPage.or')}
                  </Divider>
                  <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                      <Header icon>
                        <Icon name='user' />
                        {t('landingPage.user')}
                      </Header>
                      <p>{t('landingPage.userSubTitle')}</p>
                      <Button
                        positive={isManager === false}
                        type='button'
                        onClick={() => setIsManager(false)}
                      >
                        {isManager === false
                          ? t('landingPage.managerBtn')
                          : t('landingPage.userBtn')}
                      </Button>
                    </Grid.Column>
                    <Divider className='hide-desktop'>
                      {t('landingPage.or')}
                    </Divider>
                    <Grid.Column>
                      <Header icon>
                        <Icon name='users' />
                        {t('landingPage.users')}
                      </Header>
                      <p>{t('landingPage.usersSubTitle')}</p>
                      <Button
                        positive={isManager === true}
                        type='button'
                        onClick={() => setIsManager(true)}
                      >
                        {isManager === false
                          ? t('landingPage.managerBtn')
                          : t('landingPage.userBtn')}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              {isManager !== null && (
                <Fragment>
                  <Form.Group widths='equal'>
                    <Form.Input
                      onChange={handleChange}
                      name='firstName'
                      label={t('landingPage.formName')}
                      placeholder={t('landingPage.formName')}
                      fluid
                    />
                    <Form.Input
                      onChange={handleChange}
                      name='lastName'
                      label={t('landingPage.formSurname')}
                      placeholder={t('landingPage.formSurname')}
                      fluid
                    />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Form.Input
                      onChange={handleChange}
                      name='email'
                      label='Email'
                      placeholder='Email'
                      fluid
                    />
                    {isManager === true && (
                      <Form.Input
                        onChange={handleChange}
                        name='company'
                        label={t('landingPage.formCompany')}
                        placeholder={t('landingPage.formCompany')}
                        fluid
                      />
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <Radio
                        toggle
                        name='emailAccepted'
                        checked={emailAccepted}
                        onChange={() => setEmailAccepted(!emailAccepted)}
                      />
                    </Form.Field>
                    <label
                      htmlFor='emailAccepted'
                      className='white-text'
                      onClick={() => setEmailAccepted(!emailAccepted)}
                    >
                      <b>{t('landingPage.emailAgreed')}</b>
                    </label>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <Radio
                        toggle
                        name='termsAccepted'
                        checked={termsAccepted}
                        onChange={() => setTermsAccepted(!termsAccepted)}
                      />
                    </Form.Field>
                    <label
                      htmlFor='termsAccepted'
                      className='white-text'
                      onClick={() => setTermsAccepted(!termsAccepted)}
                    >
                      <b>{t('landingPage.Agreed')}</b>
                    </label>
                  </Form.Group>
                  <Container textAlign='center'>
                    <Button
                      disabled={!buttonEnabled()}
                      loading={requestLoading}
                      type='submit'
                      color='orange'
                    >
                      {t('landingPage.RegBtn')}
                    </Button>
                  </Container>
                </Fragment>
              )}
            </Form>
          </div>
        )}
        {contactOpen && requestSent && (
          <div className='sixteen wide column'>
            <div className='landing__form bold-text thanks'>
              {t('landingPage.thankMsg')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingForm;
