import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dimmer,
  Form,
  Icon,
  Loader,
  Modal,
  Transition,
} from 'semantic-ui-react';
import './style.css';

const ChangePasswordModal = ({ user, setUser, isOpen, closeModal }) => {
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordChangeStatus, setPasswordChangeStatus] =
    React.useState('INIT');
  const { t } = useTranslation();

  const changePassword = () => {
    if (newPassword === confirmPassword) {
      setPasswordChangeStatus('PENDING');
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password: newPassword }),
      };
      fetch('/api/v1/password/change', requestOptions)
        .then(() => {
          setPasswordChangeStatus('COMPLETED');
          setTimeout(() => {
            close();
            window.location.href = `${window.location.origin}/opirada`;
          }, 2000);
        })
        .catch(() => setPasswordChangeStatus('ERROR'));
    } else {
      setPasswordError(true);
    }
  };

  const handleChange = (e, { name, value }) => {
    if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const close = () => {
    setNewPassword('');
    setConfirmPassword('');
    setPasswordError(false);
    setPasswordChangeStatus('INIT');
    closeModal();
  };

  return (
    <Modal dimmer='inverted' open={isOpen}>
      <Modal.Header>{t('PasswordChange.MainTitle')}</Modal.Header>
      <Modal.Content>
        <div className='modal__content'>
          {['COMPLETED', 'ERROR'].indexOf(passwordChangeStatus) < 0 && (
            <Fragment>
              <Dimmer active={passwordChangeStatus !== 'INIT'} inverted>
                {passwordChangeStatus === 'PENDING' && (
                  <Loader inverted>{t('PasswordChange.Loader')}</Loader>
                )}
              </Dimmer>
              <h3>
                {user && user.custom_field_2 === 'on' ? (
                  <span>{t('PasswordChange.FirstLogin')}</span>
                ) : (
                  <span>{t('PasswordChange.NewPassword')}</span>
                )}
              </h3>
              <Form error={passwordError}>
                <Form.Input
                  onChange={handleChange}
                  name='newPassword'
                  placeholder={t('PasswordChange.NewPasswordPlaceholder')}
                  type='password'
                  value={newPassword}
                  error={passwordError}
                  fluid
                />
                <Form.Input
                  onChange={handleChange}
                  name='confirmPassword'
                  placeholder={t('PasswordChange.NewPasswordPlaceholderRepeat')}
                  type='password'
                  value={confirmPassword}
                  error={passwordError}
                  fluid
                />
              </Form>
            </Fragment>
          )}
          <Transition.Group animation='scale' duration='1000'>
            {passwordChangeStatus === 'COMPLETED' && (
              <div>
                <div className='change-password__status blue-text'>
                  <Icon name='check circle outline' size='huge' />
                  <h2>{t('PasswordChange.PasswordSuccessMsg')}</h2>
                </div>
              </div>
            )}
          </Transition.Group>
          {passwordChangeStatus === 'ERROR' && (
            <div>
              <div className='change-password__status error-text'>
                <Icon name='exclamation triangle' size='big' />
                <h4>{t('PasswordChange.PasswordErrorMsg')}</h4>
              </div>
            </div>
          )}
        </div>
      </Modal.Content>
      {['COMPLETED', 'ERROR'].indexOf(passwordChangeStatus) < 0 && (
        <Modal.Actions>
          <Button
            color='blue'
            onClick={changePassword}
            loading={passwordChangeStatus === 'PENDING'}
            disabled={passwordChangeStatus === 'COMPLETED'}
          >
            {t('PasswordChange.ChangeBtn')}
          </Button>
          <Button onClick={() => close()}>
            {t('PasswordChange.CancelBtn')}
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default ChangePasswordModal;
