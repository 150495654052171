import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const Error = ({ errorCode }) => {
  let message = "Midagi läks valesti. Palun proovi uuesti.";
  switch (errorCode) {
    case 400:
      message = "Toimingul tekkis viga.";
      break;
    case 401:
      message = "Palun sisesta avalehel korrektne autentimiskood.";
      break;
    case 404:
      message = "Oled vist eksinud..";
      break;
    default:
      break;
  }
  return (
    <div className="error">
      <div className="error-top">
        <div className="container">
          <div className="error-content">
            <div className="error-content__image">
              <img alt="Skillaby" src="skillaby_ow.png"/>
            </div>
            <div className="error-content__text">
              <h1>{errorCode}</h1>
              <p>
                {message}
              </p>
              <p>
                <Link to="/">Tagasi avalehele</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <svg id="error-bottom"
           viewBox="0 0 200 15"
           preserveAspectRatio="none"
           className="error-bottom">
        <polygon points="0,15 200,0 0,0" className="error-bottom-points"/>
      </svg>
    </div>
  );
};

export default Error;
