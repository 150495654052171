const quotes = [
  {
    content:
      'Kui sa teed plaane aastaks, külva riisi. Kui sa teed plaane kümneks aastaks, istuta puid. Kui sa teed plaane sajaks aastaks, hari inimesi.',
    author: 'Konfutsius',
  },
  {
    content:
      'Kui inimene on liiga suur, et õppida, siis ta enam suuremaks ei kasvagi',
    author: 'Herbert Victor Prochnow',
  },
  {
    content:
      'Õppimine ilma mõtlemiseta on tühi töö, mõtlemine ilma õppimiseta aga lausa ohtlik.',
    author: 'Konfutsius',
  },
  {
    content: 'Kui meeldiv on teada, et sa said midagi teada!',
    author: 'Moliere',
  },
  {
    content:
      'Hea haridus on kõige tugevam relv, mida saab kasutada maailma muutmiseks.',
    author: 'Nelson Mandela',
  },
  {
    content: 'Haridus on üks asi, mida keegi ei saa sult kunagi ära võtta.',
    author: 'Elin Nordegren',
  },
  {
    content:
      'Pole tähtis, kui aeglaselt sa liigud, oluline on mitte seisma jääda.',
    author: 'Konfutsius',
  },
  {
    content:
      'Tõeline teadmine on see, kui me teame, et me teame seda, mida me teame, ja et me ei tea seda, mida me ei tea.',
    author: 'Konfutsius',
  },
];

const quotesEng = [
  {
    content:
      'If you make plans for a year, sow rice. If you make plans for ten years, plant trees. If you make plans for a hundred years, educate people.',
    author: 'Confucius',
  },
  {
    content: 'If a man is too big to learn, he is too big to grow',
    author: 'Herbert Victor Prochnow',
  },
  {
    content:
      'Learning without thinking is labor lost; thinking without learning is perilous.',
    author: 'Confucius',
  },
  {
    content: 'How pleasant it is to know that you learned something new!',
    author: 'Moliere',
  },
  {
    content:
      'Education is the most powerful weapon which you can use to change the world.',
    author: 'Nelson Mandela',
  },
  {
    content: 'Education is one thing no one can take away from you.',
    author: 'Elin Nordegren',
  },
  {
    content:
      'It does not matter how slowly you go, as long as you do not stop.',
    author: 'Confucius',
  },
  {
    content: 'True knowledge is to know the extent of one’s ignorance.',
    author: 'Confucius',
  },
];

const quotesRus = [
  {
    content:
      'Если у тебя есть планы на год, посади рис. Если у тебя есть планы на десять лет, посади деревья. Если у тебя есть планы на сто лет, обучай людей.',
    author: 'Конфуций',
  },
  {
    content:
      'Если человек слишком велик, чтобы учиться, значит, он слишком велик, чтобы расти.',
    author: 'Герберт Виктор Прохнов',
  },
  {
    content:
      'Обучение без размышлений - потерянный труд; размышления без обучения - опасны.',
    author: 'Конфуций',
  },
  {
    content: 'Как приятно знать, что ты узнал что-то новое!',
    author: 'Мольер',
  },
  {
    content:
      'Образование - это самое мощное оружие, которое можно использовать для изменения мира.',
    author: 'Нельсон Мандела',
  },
  {
    content: 'Образование - единственное, что никто не может у тебя отнять.',
    author: 'Элин Нордегрен',
  },
  {
    content:
      'Не имеет значения, насколько медленно ты движешься, главное - не останавливайся.',
    author: 'Конфуций',
  },
  {
    content: 'Истинное знание - это знание меры своей невежественности.',
    author: 'Конфуций',
  },
];

export const getRandomQuote = () => {
  return quotes[Math.floor(Math.random() * quotes.length)];
};

export const getRandomQuoteEng = () => {
  return quotesEng[Math.floor(Math.random() * quotes.length)];
};

export const getRandomQuoteRu = () => {
  return quotesRus[Math.floor(Math.random() * quotes.length)];
};
