import React, { Fragment, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Loader, Segment, SegmentGroup } from 'semantic-ui-react';
import CreateTemplateModal from '../../components/Modal/CreateTemplateModal';
import TemplateDetailsModal from '../../components/Modal/TemplateDetailsModal';
import './style.css';

const Template = ({ user }) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState([]);

  const [categories, setCategories] = React.useState();
  const [showTemplate, setShowTemplate] = React.useState({});
  const [currentBranch, setCurrentBranch] = React.useState({ ...user.branch });
  const { t } = useTranslation();
  const getTemplates = () => {
    fetch(`/api/v1/template/list`)
      .then((response) => response.json())
      .then((response) => {
        setTemplates(response);
        console.log(response);

        let showTemplateObj = {};
        response.forEach((template) => {
          showTemplateObj[template.id] = true;
        });
        setShowTemplate({ ...showTemplateObj });

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTemplates();

    fetch(`/api/v1/courses/branch/${currentBranch.id}`)
      .then((response) => response.json())
      .then((response) => {
        setCategories(response);
      })
      .then(() => {});
    //console.log(showTemplate)
  }, []);

  const finalize = () =>
    setTimeout(() => {
      getTemplates();
      setAddModalOpen(false);
      setIsLoading(false);
    }, 1000);

  const deleteTemplate = (templateID) => {
    ///template/delete/:templateId
    fetch(`/api/v1/template/delete/${templateID}`)
      .then((response) => response.json())
      .then((response) => {
        setCategories(response);
      })
      .then(() => {});
  };

  const createTemplate = (templateName, selectedCourses) => {
    setIsLoading(true);
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api/v1/template', {
      ...requestOptions,
      body: JSON.stringify({ name: templateName }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res.json();
      })
      .then((templateId) => {
        const promises = [];
        if (Object.keys(selectedCourses).length > 0) {
          promises.push(
            Object.keys(selectedCourses).map((courseId) => {
              const courseBody = {
                courseId,
                periodDays: selectedCourses[courseId],
              };
              return fetch(`/api/v1/template/${templateId}`, {
                ...requestOptions,
                body: JSON.stringify(courseBody),
              });
            }),
          );
        }
        Promise.all(promises).then(finalize);
      })
      .catch((e) => {
        console.log(e);
        finalize();
      });
  };

  return (
    <div className='template'>
      {isLoading ? (
        <Loader active inline='centered'>
          {t('admin.Loading')}
        </Loader>
      ) : (
        <Fragment>
          <CreateTemplateModal
            user={user}
            step='NAME'
            createTemplate={createTemplate}
            isLoading={isLoading}
            isModalOpen={addModalOpen}
            setIsModalOpen={setAddModalOpen}
          />
          {selectedTemplate && (
            <TemplateDetailsModal
              template={selectedTemplate}
              close={() => setSelectedTemplate(null)}
              user={user}
              categories={categories}
            />
          )}
          {templates.map((template) =>
            showTemplate[template.id] ? (
              <SegmentGroup
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Segment
                  style={{
                    width: '100%',
                    margin: '0px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  className='template-item'
                  onClick={() => setSelectedTemplate(template)}
                >
                  {template.name}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '30px',
                    }}
                  >
                    <a>
                      <i className='icon eye' />
                      Vaata
                    </a>
                  </div>
                </Segment>
                <Button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0 15px',
                  }}
                  size='mini'
                  color='red'
                  icon='trash'
                  content='Kustuta'
                  onClick={() => {
                    deleteTemplate(template.id);

                    let showTemplateObj = showTemplate;
                    showTemplateObj[template.id] = false;
                    setShowTemplate({ ...showTemplateObj });
                  }}
                />
              </SegmentGroup>
            ) : null,
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Template;
