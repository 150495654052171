import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';

const Terms = () => {
  const { t } = useTranslation();
  return (
    <div className='terms'>
      <div className='ui stackable grid container centered'>
        <div className='sixteen wide column'>
          <span
            dangerouslySetInnerHTML={{
              __html: t('landingPage.Terms'),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Terms;
