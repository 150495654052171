import { LiveChatWidget } from '@livechat/widget-react';
import React, { useEffect } from 'react';
import ReactGa from 'react-ga';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import Page from './components/Layout/Page';
import Authentication from './pages/Authentication';
import Contact from './pages/Contact';
import Courses from './pages/Courses';
import ErrorPage from './pages/ErrorPage';
import ForgotPassword from './pages/ForgotPassword';
import Landing from './pages/Landing';
import MyfitnessAuthentication from './pages/MyfitnessAuthentication';
import Path from './pages/Path';
import Template from './pages/Template';
import Terms from './pages/Terms';
import Users from './pages/Users';

function App() {
  const [user, setUser] = React.useState(null);
  const [userLoading, setUserLoading] = React.useState(true);
  const [courseDetails, setCourseDetails] = React.useState(null);
  const [courseLoading, setCourseLoading] = React.useState(null);

  const verifyUser = (callback) => {
    fetch('/api/v1/authentication/verify')
      .then((response) => response.json())
      .then((response) => {
        setUser(response);
        callback && callback();
      });
  };

  React.useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get('token')) {
      verifyUser(() => {
        const courseId = getCourseId();
        if (courseId) {
          getCourseDetails(courseId, true);
        }
      });
    }
    setUserLoading(false);
    ReactGa.initialize('UA-150296495-3');
    ReactGa.pageview(window.location.pathname + window.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user.loginCount === 2) {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.get('session')) {
        urlParams.set('session', 'nps2');
        window.location.search = urlParams;
      }
    }
  }, [user]);

  const getCourseId = () => {
    if (window && window.location && window.location.pathname === '/kursused') {
      const queryParams = new URLSearchParams(window.location.search);
      return queryParams.get('courseId');
    }
  };

  const getCourseDetails = (courseId, isDisabled, isEditable) => {
    setCourseLoading(courseId);
    fetch(`/api/v1/course/${courseId}`)
      .then((response) => response.json())
      .then((response) => {
        const course = {
          ...response,
          isDisabled,
          isEditable,
        };
        setCourseDetails(course);
        setCourseLoading(null);
      });
  };

  const joinCourse = (courseId) => {
    setCourseLoading(courseId);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ courseId }),
    };
    fetch(`/api/v1/course/${courseId}/join`, requestOptions)
      .then(() => {
        setCourseDetails(null);
        setCourseLoading(null);
      })
      .catch();
  };

  const leaveCourse = (courseId) => {
    setCourseLoading(courseId);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ courseId }),
    };
    fetch(`/api/v1/course/${courseId}/leave`, requestOptions)
      .then(() => {
        setCourseDetails(null);
        setCourseLoading(null);
      })
      .catch();
  };

  const getAuthOrLoading = () => {
    if (userLoading) {
      return (
        <Dimmer active inverted>
          <Loader inverted>Tegutsen...</Loader>
        </Dimmer>
      );
    }
    return <Authentication />;
  };

  const isDemo =
    user &&
    user.branch &&
    user.branch.name &&
    user.branch.name.indexOf('demo') > -1;

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Landing user={user} />} />
        <Route
          path='/'
          element={
            <Page
              user={user}
              courseDetails={courseDetails}
              joinCourse={joinCourse}
              leaveCourse={leaveCourse}
              setCourseDetails={setCourseDetails}
              courseLoading={courseLoading}
              setCourseLoading={setCourseLoading}
              setUser={setUser}
              isDemo={isDemo}
            />
          }
        >
          <Route
            path='opirada'
            element={
              !user ? (
                getAuthOrLoading()
              ) : (
                <Path
                  user={user}
                  getCourseDetails={getCourseDetails}
                  courseLoading={courseLoading}
                />
              )
            }
          />
          <Route
            path='kursused'
            element={
              !user ? (
                getAuthOrLoading()
              ) : (
                <Courses
                  user={user}
                  joinCourse={joinCourse}
                  getCourseDetails={getCourseDetails}
                  courseLoading={courseLoading}
                />
              )
            }
          />
          <Route
            path='kasutajad'
            element={
              !user ? (
                getAuthOrLoading()
              ) : (
                <Users user={user} verifyUser={verifyUser} />
              )
            }
          />
          <Route
            path='opirajad'
            element={!user ? getAuthOrLoading() : <Template user={user} />}
          />
          <Route path='login' element={<Authentication />} />
          <Route path='myfitness' element={<MyfitnessAuthentication />} />
          <Route path='parool-ununes' element={<ForgotPassword />} />
          <Route
            path='kontakt'
            element={
              <>
                <Contact />
                <LiveChatWidget
                  license='9831280'
                  visibility='minimized'
                  group='0'
                />
              </>
            }
          />
          <Route path='tingimused' element={<Terms />} />
        </Route>
        <Route path='*' element={<ErrorPage user={user} error={404} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
