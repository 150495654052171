import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Popup,
  Segment,
  Checkbox as SemanticUICheckbox,
} from 'semantic-ui-react';
import '../style.css';
//import { formatTalentDate } from '../../../../helpers/helpers';
//import UserProgressModal from '../../../Modal/UserProgressModal';

const CustomCheckbox = ({
  checked,
  label,
  onChange,
  checkboxId,
  userID,
  onNameClick,
}) => {
  const handleLabelClick = (e) => {
    console.log('hi');
    e.stopPropagation(); // Prevent the click event from reaching the input element
  };

  const testMe = (par) => {
    console.log(par);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
      {/* <input
        type="checkbox"
        id={checkboxId}
        checked={checked}
        onChange={onChange}
      /> */}
      <SemanticUICheckbox
        id={checkboxId}
        checked={checked}
        onChange={onChange}
        label={null}
      />
      <p title='Vaata kasutaja progressi' onClick={onNameClick}>
        {label}
      </p>
    </div>
  );
};

const UserRowSegment = ({
  user,
  branchUser,
  currentBranch,
  branchLoading,
  removeUser,
  removedUsers,
  toggleUserRemove,
  setProgressUser,
  setDeactivationUser,
}) => {
  const { t } = useTranslation();
  return (
    <Segment
      key={`user-${branchUser.id}`}
      className={`users-segment__item ${
        removedUsers.indexOf(branchUser.id) > -1 &&
        'users-segment__item--selected'
      }`}
    >
      {branchUser.id !== user.id ? (
        <Fragment>
          {/* <Checkbox
   checked={removedUsers.indexOf(branchUser.id) > -1}
   label={`${branchUser.firstName} ${branchUser.lastName}`}
   onChange={() => toggleUserRemove(branchUser.id)}
 /> */}
          <CustomCheckbox
            checkboxId={`checkbox_${branchUser.id}`}
            checked={removedUsers.indexOf(branchUser.id) > -1}
            label={`${branchUser.firstName} ${branchUser.lastName}`}
            onChange={() => toggleUserRemove(branchUser.id)}
            userID={branchUser}
            onNameClick={() => setProgressUser(branchUser)}
            /* progressUser={branchUser} */
          />
          <div
            className='users-segment__item-progress'
            onClick={() => setProgressUser(branchUser)}
          >
            &nbsp;
          </div>
          <span>
            {/* <UserProgressModal
     progressUser={branchUser}
   />   */}
            <Popup
              content={t('admin.DownloadReportText')}
              trigger={
                <a
                  className='orange-text'
                  href={`https://${
                    currentBranch.name
                  }-skillaby.talentlms.com/reports/usertoexcel/id:${
                    branchUser.id
                  },token:${new Date().getTime()}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='icon file alternate outline' />
                </a>
              }
            />
            &nbsp;
            {/* <Popup
     content="Määra kasutaja deaktiveerimise kuupäev"
     trigger={
       <Button
         color="orange"
         size="mini"
         onClick={() => {
           const current = formatTalentDate(branchUser.deactivationDate);
           setDeactivationUser({
             id: branchUser.id,
             current,
           })
         }}
         icon="time"
         label={{
           as: 'span',
           className: 'secondary-text',
           content: branchUser.deactivationDate
             ? branchUser.deactivationDate.split(',')[0]
             : 'Tähtajatu'
         }}
         labelPosition="right"
       />
     }
   /> */}
            &nbsp;
            <Popup
              trigger={
                <Button
                  disabled={
                    branchLoading === currentBranch.id ||
                    removedUsers.length > 0
                  }
                  size='mini'
                  color='red'
                  icon='remove'
                  content={t('admin.RemoveUser')}
                  basic
                />
              }
              content={
                <div>
                  <b>{t('admin.SureNotif')}</b>
                  <br />
                  <br />
                  {t('admin.DownloadBeforeDelete')}&nbsp;
                  <a
                    className='orange-text'
                    href={`https://${currentBranch.name}-skillaby.talentlms.com/reports/userinfo/id:${branchUser.id} `}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('admin.UserReport')}
                  </a>
                  .
                  <br />
                  <br />
                  <Button
                    fluid
                    size='mini'
                    color='red'
                    onClick={() => removeUser(branchUser.id)}
                    disabled={branchLoading === currentBranch.id}
                  >
                    <i className='icon remove' />
                    {t('admin.Yes')}
                  </Button>
                </div>
              }
              on='click'
              position='top center'
            />
          </span>
        </Fragment>
      ) : (
        <span>
          <i className='icon user' />
          &nbsp;&nbsp;{`${branchUser.firstName} ${branchUser.lastName}`}
        </span>
      )}
    </Segment>
  );
};

export default UserRowSegment;
