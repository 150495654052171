export const enTranslation = {
  landingPage: {
    title:
      'WE BELIEVE THAT <span class="white-text">DIGITAL</span> ONBOARDING IS THE <span class="white-text">FUTURE</span>.',
    subtitle:
      'Skillaby is a web platform for HR professionals that helps simplify, automate, and make the onboarding process of new employees more transparent.',
    userRegBtn: 'TRY <span style="color: #e5422d;">SKILLABY</span> FOR FREE',
    userRegTitle: 'What type of demo account would you like to create?',
    user: 'Learner',
    users: 'HR Manager',
    userSubTitle:
      'Choose the learner demo if you are an individual and want to see only the learning environment.',
    usersSubTitle:
      'Choose the HR manager demo if you represent a company and want to see the HR management environment. The learner view is also visible to you.',
    userBtn: 'Choose',
    managerBtn: 'Chosen',
    or: 'or',
    formName: 'Name',
    formSurname: 'Surname',
    formCompany: 'Company',
    emailAgreed: 'I agree to receive emails',
    Agreed: 'I agree to the terms and conditions',
    RegBtn: 'I am ordering a demo environment',
    thankMsg:
      'Thank you! The demo environment is being created, and we will soon send more detailed information to your email.',
    AccMainTitle: 'What can be done with Skillaby',
    AccTitle1: 'Personal learning paths',
    AccDesc1: `
			<p>
				<strong>Problem</strong>: The new employee is overloaded with information. The necessary information materials are
				scattered in several different places and dispersed among other documents. He lacks
				an overview of how he is progressing with his adaptation.
			</p>
			<p>
				<strong>Solution</strong>: The HR specialist can create a learning path for each
				employee that suppresses excess information noise. On the personal learning path, the employee sees only those materials
				that are important to him. They are consolidated in one place, organized and
				scheduled. This gives the employee clarity about his progress, shows him what the next
				step is, and also allows him to plan his time better.
			</p>
			<p>
				The learning path remains with the employee. This allows him to always return to the materials if he
				feels the need to refresh his memory.
			</p>
		`,
    AccTitle2: 'Reports and <i>compliance</i> management',
    AccDesc2: `
			<p>
				<strong>Problem:</strong> The HR specialist lacks an overview of how the employee onboarding is progressing. They are concerned about whether all critical activities, such as familiarizing themselves with the safety instructions, have been completed. Is the new employee on track with onboarding? Has anything important been overlooked?
			</p>
			<p>
				<strong>Solution:</strong> The employee's progress on their learning path is continuously visible to the HR specialist. Since the steps are deadline-oriented, it is possible to see whether the employee is on track with their onboarding or not.
			</p>
			<p>
				An overview of completed materials, such as the employee's confirmation of reviewing the safety instructions, is stored in Skillaby and is easily accessible in a form that allows written playback (<i>compliance</i> management).
			</p>
		`,

    AccTitle3: 'Tests',
    AccDesc3: `
			<p>
				<strong>Problem:</strong> Has the employee genuinely acquired the information in the materials, or have they simply clicked through them?
			</p>
			<p>
				<strong>Solution:</strong> Tests can be created for the learning materials (or before and in between) to ensure that the employee has acquired the information. Tests can be created in various formats, and test questions can be rotated to display different questions each time (eliminating the trial-and-error method of passing the test).
			</p>
			<p>
				If the employee does not pass the test to the required level, they can be redirected back to the materials. The HR specialist can view all test attempts and results.
			</p>
		`,

    AccTitle4: 'Learning Material Design',
    AccDesc4: `
			<p>
				<strong>Problem:</strong> (a) The materials necessary for onboarding are monotonous. The attention of employees during information acquisition is dispersed, making onboarding dull and exhausting. The learned information does not stick. (b) Onboarding materials in digital form are missing (everything is printed out or in face-to-face training format).
			</p>
			<p>
				<strong>Solution:</strong> In addition to the platform, Skillaby offers instructional design services, where onboarding is transformed into a format designed for independent e-learning. This makes onboarding more effective and exciting for new employees, thanks to engaging and inclusive learning materials.
			</p>
			<p>
				<a href='https://skillaby.talentlms.com/shared/start/key:ZGSDNHRK'>
					Check out Skillaby's instructional design sample material.
				</a>
			</p>
		`,
    CourseTitle: 'Existing Courses',
    RightsLink: 'Privacy and Terms of Use',
    RightsTitle: 'Skillaby Terms of Use',
    CloseBtn: 'Close',
    Terms: `<p>
	<strong>Skillaby Terms of Use</strong>
</p>
<br />
<p>Terms of use effective from 01.05.2023</p>
<br />
<p>
	These terms of use regulate the digital skills e-learning service provided by BCS Koolitus through the Skillaby environment, aimed at helping the Client manage individual training more easily and facilitate the planning and implementation of individuals' professional development more conveniently.
</p>
<h3>1. Definitions</h3>
<ul>
	<li>
		<strong>BCS Koolitus -</strong> BCS Koolitus AS (registry code
		107230477, address Aia 7, Tallinn 10111).
	</li>
	<li>
		<strong>Client</strong> – an individual who has entered into a contract with BCS
		Koolitus for the use of the digital skills e-learning service.&nbsp;
	</li>
	<li>
		<strong>Skillaby Environment&nbsp;</strong>– the cloud-based
		e-learning service environment, which includes digital skills learning nuggets (video, audio, and text materials); interactive exercises consolidating what has been learned; the opportunity, depending on the package, to store the Client's own study materials; the ability to create personal learning paths from study materials; the ability to track learners' progress in completing study materials.
	</li>
	<li>
		<strong>End User</strong> – an individual to whom the Client grants
		access to the Skillaby environment.
	</li>
</ul>
<h3>2. Digital Skills E-Learning Service</h3>
<ul>
	<li>
		The Client has access to the cloud-based e-learning service
		environment called Skillaby.
	</li>
	<li>
		The Client has the right to grant access to the Skillaby environment
		to their employee or another person.
	</li>
	<li>
		The End User has access to the learning path created by the Client
		and the study materials contained therein.
	</li>
	<li>
		The Client has access to information on the completion of End Users' learning nuggets and learning analytics.
	</li>
	<li>The Skillaby environment is available to the Client 24/7.</li>
	<li>
		BCS Koolitus continuously develops and enhances the Skillaby
		environment, including the learning nuggets bank, to provide the Client and End User with increasingly high-quality and versatile services.
	</li>
</ul>
<h3>3. Pricing</h3>
<ul>
	<li>
		The service price can be calculated according to the Client's needs
		using the Skillaby price calculator, which can be found on the Skillaby website (
		<a href='https://skillaby.bcskoolitus.ee/'>
			https://skillaby.bcskoolitus.ee
		</a>
		/).
	</li>
	<li>
		BCS Koolitus invoices the Client for the upcoming period no later
		than the start date of the new period.
	</li>
</ul>
<h3>4. Processing of Personal Data</h3>
<ul>
	<li>
		To create access to the service, BCS Koolitus processes the following personal data about the Client and End User: full name, email address, phone number, and company name.&nbsp;
	</li>
	<li>
		For the purpose of providing the service, BCS Koolitus processes data generated during the provision of the service: courses assigned to the End User; completed, incomplete, and ongoing courses; results of tests and exercises done in Skillaby; the End User's last login; how often the user logs in; how long the user spends on the platform.
	</li>
	<li>
		The authorized data processor for personal data during the provision of the service is BCS Koolitus,
		<a href='mailto:kool@bcs.ee'>kool@bcs.ee</a>, phone
		680 6880.
	</li>
	<li>
		Personal data is stored for up to one month after the user's access account is closed unless otherwise required by legislation.
	</li>
	<li>
		BCS Koolitus may disclose personal data to third parties providing services to BCS Koolitus, with whom BCS Koolitus has concluded a contract and who are obliged to ensure appropriate protection measures for processing personal data. BCS Koolitus provides personal data to the service provider of the Skillaby environment platform.
	</li>
	<li>
		In accordance with the requirements set out in applicable legislation, BCS Koolitus ensures that personal data is protected by appropriate technical and organizational measures to prevent unauthorized access, unlawful processing or disclosure, accidental loss, alteration, or destruction. BCS Koolitus implements organizational, physical, IT, and other necessary security measures to ensure effective protection of personal data.&nbsp;
	</li>
	<li>
		Privacy and terms of use for using the service are provided on the BCS Koolitus AS website: skillaby.bcskoolitus.ee/terms. By starting to use the service, the Client confirms that they have read and agree to the aforementioned terms.&nbsp;
	</li>
</ul>
<h3>5. Intellectual Property</h3>
<ul>
	<li>
		Parts of the service and the study materials contained therein are the intellectual property of BCS Koolitus or third parties associated with the provision of the service, and all rights related to them belong to them.
	</li>
	<li>
		The Client and End User are not allowed, without the written consent of BCS Koolitus:&nbsp;
		<ul>
			<li>
				Download, save, or make available to another person outside the BCS Koolitus digital skills e-learning environment any learning content (study materials, parts of the learning environment, etc.), except for content intended for download by BCS Koolitus (task files, summary files, etc.);
			</li>
			<li>
				sell, modify, or otherwise distribute the content of the service;
			</li>
			<li>
				use the service for purposes not specified in the service description.&nbsp;
			</li>
		</ul>
	</li>
	<li>
		If the Client or End User violates the obligation mentioned in the previous point of the Agreement, BCS Koolitus has the right to demand a penalty of 10,000 euros for each violation.
	</li>
</ul>
<h3>6. Changes to Terms of Use</h3>
<ul>
	<li>
		BCS Koolitus may change the terms of use at any time, notifying
		you at least 14 days in advance. The changes take effect 14 days after
		they are published at the web address
		skillaby.bcskoolitus.ee/terms. The exact date of entry into force of
		the changes is separately indicated on the same website. If you
		continue to use the Skillaby environment after the changes take
		effect, you will be deemed to have accepted the changes. If you do
		not agree with the new terms, you have the right to terminate access
		to the Skillaby environment.
	</li>
</ul>
<h3>7. Termination of Service Use</h3>
<ul>
	<li>
		The Client has the right to terminate access to the service at any
		time by notifying BCS Koolitus in writing of the desire to terminate
		the service.
	</li>
	<li>
		BCS Koolitus has the right to terminate the Client's access to the Skillaby environment, notifying the Client in writing 30 calendar days in advance. According to the agreement between the parties, it is possible to terminate the use of the service with a shorter notice period.
	</li>
</ul>`,
    AddMore: 'More will be added in the near future:',
    ModulesTitle: 'Within this topic, you can complete the following modules:',
    LoginTitle:
      'If you already have access to Skillaby, you can log in on this page.',
    LoginError: 'Incorrect username or password',
    LogBtn: 'Start Learning!',
    ForgotPswd: 'Forgot Password?',
    RecoveryTitle:
      'Enter your details, and instructions for setting a new password will be sent to your email.',
    RecoveryBtn: 'Send',
  },
  admin: {
    Greeting: 'Hello',
    ForgotPswd: 'Change Password',
    Logout: 'Logout',
    CookieConsent: `By starting to use the service, the user confirms that they are
		aware of and adhere to the conditions of intellectual property protection, as well as
		agree to the terms of processing and protection of personal data.`,
    CookiePrivacyBtn: 'Read more.',
    CookieCloseBtn: 'I understood',
    UserTermsTitle: 'End User Terms of Service',
    UserTermsAgree: 'Agree',
    UserTermsDisagree: 'Disagree',
    Loading: 'Loading...',
    ModuleDesc: 'Module Description',
    AddModuleText: 'Add module',
    JoinModuleText: 'Join Course',
    ModuleSteps: 'Module Steps',
    AddModule: 'Add Module',
    DeleteModule: 'Delete Module',
    RemoveModule: 'Delete module',
    SureNotif: 'Are you sure?',
    RemoveText: 'Delete',
    CurseLinkBtn: 'Open<span className="hide-mobile"> module</span>',
    InfoText: 'Information',
    HideModuleBtn: 'Modify<span className="hide-mobile"> module</span>',
    LearningPath: 'My learning path',
    LearningProgress: 'Learner progress!',
    BranchReport: 'Branch report',
    AddUsers: 'Add users',
    SureUserNotif: 'This action cannot be undone. Are you completely sure?',
    Yes: 'Yes',
    SelectAllBtn: 'Select all',
    RemoveSelected: 'Remove Selected',
    DownloadReportText: 'Download the user progress report',
    RemoveUser: 'Remove',
    DownloadBeforeDelete: 'Download before delete',
    UserReport: 'User report',
    AddingBranchUser: 'Adding users to the branch',
    AddingMethod: 'Choose how you want to add users:',
    FirstMethod: 'One by one',
    SecondMethod: 'By importing from a file',
    AddUserBtn: 'Add',
    Back: 'Back',
    Next: 'Next',
    CourseAddingText: `Select the study track or courses you want for the users to be added
		set for passing. All other courses remain with the user
		for voluntary passage.`,
    CoursePathText: 'Learning path',
    RemoveAll: 'Remove all',
    AddSuccessMsg: 'Import completed. You can close the window.',
    AddErrorMsg:
      'There were problems adding some users. Please check the data and try again.',
    AddLoadingMsg: 'Do not close the web window. Users are being imported.',
    CloseWindow: 'Close window',
    DeadlineMsg: 'Choose deadlines',
    DeadTimePickerMsg: 'No due date',
    ReadFromFile: 'Read from file',
    ImportUsersMsg: `To import users, <a href='import.xlsx'>download the table template</a> and add the corresponding data. Then, you can upload the file and confirm the addition of users`,
    ContactInfo1: `<h3>Do you have any questions regarding Skillaby?</h3> <p>The Skillaby team is here to help you with all our knowledge and efforts!</p> <p> Feel free to write to us at <a href='mailto:skillaby@bcs.ee'>skillaby@bcs.ee</a> or call us at <a href='tel:+3726806880'>680 68 80</a>. </p>`,
    ContactInfo2: `<h3>BCS Koolitus AS</h3> <p> Aia 7, Tallinn 10111 <br /> Phone 680 68 80 <br /> skillaby@bcs.ee </p>`,
  },
  PasswordChange: {
    MainTitle: 'Change Password',
    Loader: 'Processing...',
    FirstLogin: 'Please change your password after the first login.',
    NewPassword: 'Please enter a new password',
    NewPasswordPlaceholder: 'New password',
    NewPasswordPlaceholderRepeat: 'Repeat new password',
    PasswordSuccessMsg: 'Password changed!',
    PasswordErrorMsg: 'An error occurred, please try again.',
    ChangeBtn: 'Change',
    CancelBtn: 'Cancel',
  },
};
