import { Button, Popup, Segment } from 'semantic-ui-react';
import '../style.css';

const UsersTitleSegment = ({ branchLoading, currentBranch, user, switchBranch }) => (
  <Segment className="segment--blue">
    <h5 className="users-segment__title">
      <span>
       {branchLoading
         ? <i className="ui active inline loader tiny"/>
         : <i className="group icon"/>}
        &nbsp;{currentBranch.name}&nbsp;
      </span>
      {user && user.branches && user.branches.length > 1 && (
        <Popup
          trigger={
            <Button
              size="mini"
              content="Vaheta haru"
            />
          }
          content={
            <div>
              {user.branches.map((branch) => (
                <Button
                  key={`switch-branch-${branch.id}`}
                  disabled={currentBranch.id === branch.id}
                  onClick={() => switchBranch(branch)}
                >
                  {branch.name}
                </Button>
              ))}
            </div>
          }
          on="click"
          position="top center"
        />
      )}
    </h5>
  </Segment>
);

export default UsersTitleSegment;
