import React, { Fragment } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { Popup, Radio, Segment } from 'semantic-ui-react';
import { formatDate } from '../../../../helpers/helpers';
import './style.css';

const CourseDeadlineSegment = ({
  course,
  openedCalendar,
  setOpenedCalendar,
  selectedCourses,
  selectCourse,
  minDate,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <Segment
      key={`course-${course.id}`}
      className='course-deadline-segment__item'
    >
      <div className='course-deadline-segment__item-main'>
        {icon && (
          <img
            className='course-deadline-segment__item-logo'
            alt={course.name}
            src={icon}
          />
        )}
        {course.name}
      </div>
      {Object.keys(selectedCourses).includes(course.id) && (
        <div className='course-deadline-segment__item-menu'>
          <Radio
            toggle
            name={`dl-${course.id}`}
            checked={!!selectedCourses[course.id]}
            onChange={() => {
              if (!!selectedCourses[course.id]) {
                setOpenedCalendar(null);
                selectCourse(null, course.id, null);
              } else {
                setOpenedCalendar(course.id);
              }
            }}
          />
          <Popup
            style={{ zIndex: 99999999999999, padding: 2 }}
            open={openedCalendar === course.id}
            onOpen={() => setOpenedCalendar(course.id)}
            onClose={() => setOpenedCalendar(null)}
            trigger={
              <div className='category-deadline'>
                <i className='edit icon small' />
                {formatDate(selectedCourses[course.id]) ||
                  t('admin.DeadTimePickerMsg')}
              </div>
            }
            content={
              <Calendar
                locale='et-ET'
                minDate={minDate}
                onChange={(val) => selectCourse(null, course.id, val)}
                value={selectedCourses[course.id] || minDate}
              />
            }
            on='click'
            position='top center'
          />
        </div>
      )}
    </Segment>
  );
};

/*{
  (<span>
                  <i className="edit icon small"/>
    {formatDate(selectedCourses[course.id]) || 'Tähtajatu'}
                </span>)
}*/

/*              <a
                onClick={() => setOpenedCalendar(course.id)}
                className="category-deadline"
              >
                <i className="edit icon small"/>
                {formatDate(selectedCourses[course.id]) || 'Tähtajatu'}
              </a>*/

const DeadlinesSegment = ({ categories, selectedCourses, selectCourses }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [openedCalendar, setOpenedCalendar] = React.useState(null);

  const selectCourse = (e, courseId, date) => {
    if (e) e.stopPropagation();
    selectCourses({ [courseId]: date });
    setOpenedCalendar(null);
  };

  const minDate = new Date();
  minDate.setDate(new Date().getDate() + 1);

  const getCategoryDeadline = (category) => {
    if (category.courses.find((course) => course.deadline)) {
      return Math.min(
        ...category.courses
          .filter((course) => course.deadline)
          .map((course) => new Date(course.deadline)),
      );
    }
    return null;
  };

  const sortCategories = (a, b) => {
    const datesA = a.courses
      .filter((course) => !!selectedCourses[course.id])
      .map((course) => selectedCourses[course.id]);
    const datesB = b.courses
      .filter((course) => !!selectedCourses[course.id])
      .map((course) => selectedCourses[course.id]);
    if (datesA.length === 0 || datesB.length === 0) {
      return datesB.length - datesA.length;
    }
    const minA = datesA.reduce(function (a, b) {
      return a < b ? a : b;
    });
    const minB = datesB.reduce(function (a, b) {
      return a < b ? a : b;
    });
    if (minB > minA) return -1;
    if (minB === minA) return 0;
    return 1;
  };

  const sortCourses = (a, b) => {
    if (!selectedCourses[a.id] || !selectedCourses[b.id]) {
      return (
        Number(selectedCourses[b.id] || false) -
        Number(selectedCourses[a.id] || false)
      );
    }
    if (selectedCourses[b.id] > selectedCourses[a.id]) return -1;
    if (selectedCourses[b.id] === selectedCourses[a.id]) return 0;
    return 1;
  };

  return (
    <Fragment>
      {categories
        .filter(
          (category) =>
            !!category.courses.find(
              (course) => Object.keys(selectedCourses).indexOf(course.id) > -1,
            ),
        )
        .sort(sortCategories)
        .map((category) => {
          const categoryCourses = category.courses.filter(
            (course) => Object.keys(selectedCourses).indexOf(course.id) > -1,
          );
          const isSingle = categoryCourses.length === 1;
          return (
            <Segment.Group className='course-deadline-segment'>
              {isSingle ? (
                <CourseDeadlineSegment
                  course={categoryCourses[0]}
                  selectCourse={selectCourse}
                  selectedCourses={selectedCourses}
                  openedCalendar={openedCalendar}
                  setOpenedCalendar={setOpenedCalendar}
                  minDate={minDate}
                  icon={category.icon}
                />
              ) : (
                <Segment>
                  <h5 className='course-deadline-segment__title'>
                    <img
                      className='course-deadline-segment__item-logo'
                      alt={category.name}
                      src={category.icon}
                    />
                    <span onClick={() => setIsOpen(!isOpen)}>
                      {category.name}
                    </span>
                  </h5>
                </Segment>
              )}
              {isOpen &&
                !isSingle &&
                categoryCourses
                  .sort(sortCourses)
                  .map((course) => (
                    <CourseDeadlineSegment
                      course={course}
                      selectCourse={selectCourse}
                      selectedCourses={selectedCourses}
                      openedCalendar={openedCalendar}
                      setOpenedCalendar={setOpenedCalendar}
                      minDate={minDate}
                    />
                  ))}
            </Segment.Group>
          );
        })}
    </Fragment>
  );
};

export default DeadlinesSegment;
