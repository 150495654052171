import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Loader, Modal } from 'semantic-ui-react';
import TemplateDeadlinesSegment from '../../Segment/TemplateDeadlinesSegment';
import AddCourseSegment from '../ImportUsersModal/AddCourseSegment';
import './style.css';
//
const templateFlowSteps = ['DEFAULT', 'ADDCOURSES', 'DEADLINES'];

const TemplateDetailsModal = ({ template, close, user, categories }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [templateCourses, setTemplateCourses] = useState([]);
  const [globalBTNswitch, setGlobalBTNswitch] = React.useState({});

  const [selectedCourses, setSelectedCourses] = React.useState({});
  const [addCoursesModal, setAddCoursesModal] = React.useState(false);
  const [templateNameList, setTemplateNameList] = React.useState({});

  const [newlyAddedCourses, setNewlyAddedCourses] = React.useState({});
  const [newCoursesDates, setNewCoursesDates] = React.useState({});

  const [currentStep, setCurrentStep] = useState(templateFlowSteps[0]);
  const { t } = useTranslation();

  useEffect(() => {
    getTemplates();
  }, [template]);

  const getTemplates = async () => {
    setIsLoading(true);

    fetch(`/api/v1/template/${template.id}/courses`)
      .then((response) => response.json())
      .then((response) => {
        setTemplateCourses(response);

        setIsLoading(false);
        return response;
      })
      .then((response) => {
        let selectedCoursesObj = {};

        let templateNameObj = {};
        response.forEach((cat) => {
          templateNameObj[cat.name] = true;
          cat.courses.forEach((course) => {
            selectedCoursesObj[course.id] = null;
          });
        });

        setSelectedCourses({ ...selectedCoursesObj });
        setTemplateNameList({ ...templateNameObj });
        setIsLoading(false);
      });
  };

  const updateTemplate = async (par_templateID) => {
    let newCourses;
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    };
    await fetch(`/api/v1/template/update/${par_templateID}`, {
      ...requestOptions,
      body: JSON.stringify({
        selectedCourses /* name: templateName, courseID, days */,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let tempObj = {};

        for (let i = 0; i < data.length; i++) {
          let id = data[i];
          tempObj[id] = null;
        }

        newCourses = tempObj;
      })
      .catch((error) => {
        console.log('found error', error);
      });
    setNewlyAddedCourses({ ...newCourses });

    return newCourses;
  };

  const selectCourses = async (courses) => {
    let selected = {};

    Object.keys(selectedCourses).forEach((courseID) => {
      selected[courseID] = null;
    });

    Object.keys(courses).forEach((course) => {
      selected[course] = null;
    });

    await setSelectedCourses(selected);
  };

  const deselectCourses = (courseIds) => {
    if (courseIds) {
      const selected = { ...selectedCourses };
      courseIds.forEach((courseId) => {
        if (Object.keys(selected).includes(courseId)) {
          delete selected[courseId];
        }
      });
      setSelectedCourses(selected);
    }
  };

  const btnSwitcher = (obj) => {
    setGlobalBTNswitch({ ...obj });
  };

  const updateNewCoursesDates = async (par_templateID) => {
    /*     console.log("updateNewCoursesDates", par_templateID)
    console.log("newCoursesDates",newCoursesDates) */
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    };
    await fetch(`/api/v1/template/update/newCoursesDates/${par_templateID}`, {
      ...requestOptions,
      body: JSON.stringify({ datesObj: newCoursesDates }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!!data) return;
      })
      .catch((error) => {
        return console.log('found error', error);
      });
  };

  const selectNewCourseDate = (e, courseId, days) => {
    const value = days === 0 ? null : days;

    /* console.log("selectNewCourseDate", courseId, value) */
    let obj = newCoursesDates;
    obj[courseId] = Number(value);

    setNewCoursesDates({ ...obj });
  };

  let currentModal;
  switch (currentStep) {
    case 'ADDCOURSES': {
      currentModal = (
        <Modal dimmer='inverted' onClose={close}>
          <Modal.Header>Lisa või eemalda kursuseid</Modal.Header>
          <Modal.Content>
            <div className='add-users__content'>
              <p>
                Vali kursused mida soovid õpirajale lisada või sellest
                eemaldada. Kõik ülejäänud kursused jäävad kasutajale
                vabatahtlikuks läbimiseks.
              </p>
              <div>
                <br />
                {categories &&
                  categories.map((category) => (
                    <AddCourseSegment
                      category={category}
                      selectedCourses={selectedCourses}
                      selectCourses={selectCourses}
                      deselectCourses={deselectCourses}
                      templateCoursesMatch={templateCourses}
                      templateNameList={templateNameList}
                    />
                  ))}
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                return setCurrentStep(templateFlowSteps[0]);
              }}
            >
              Tagasi
            </Button>
            <Button
              color='blue'
              onClick={async () => {
                await updateTemplate(template.id).then(async (newCourses) => {
                  console.log(newCourses);
                  setNewlyAddedCourses({ ...newCourses });

                  console.log(Object.keys(newCourses).length);
                  if (Object.keys(newCourses).length == 0) {
                    await getTemplates().then(() => {
                      return setCurrentStep(templateFlowSteps[0]);
                    });
                  } else {
                    console.log(newlyAddedCourses);
                    console.log(selectedCourses);
                    return setCurrentStep(templateFlowSteps[2]);
                  }
                });
              }}
            >
              Salvesta
            </Button>
          </Modal.Actions>
        </Modal>
      );
      break;
    }
    case 'DEADLINES': {
      currentModal = (
        <Modal dimmer='inverted' onClose={close}>
          <Modal.Header>Lisa uutele kursustele tähtajad</Modal.Header>
          <Modal.Content>
            <div>
              <TemplateDeadlinesSegment
                selectedCourses={newlyAddedCourses}
                selectNewCourseDate={selectNewCourseDate}
                categories={categories}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                return setCurrentStep(templateFlowSteps[0]);
              }}
            >
              Tagasi
            </Button>
            <Button
              color='blue'
              onClick={async () => {
                updateNewCoursesDates(template.id).then(async () => {
                  await getTemplates().then(() => {
                    return setCurrentStep(templateFlowSteps[0]);
                  });
                });
              }}
            >
              Salvesta
            </Button>
          </Modal.Actions>
        </Modal>
      );
      break;
    }
    case 'DEFAULT':
    default: {
      currentModal = (
        <Modal dimmer='inverted' onClose={close} open={!!template}>
          <Modal.Header>Õpirada: {template.name}</Modal.Header>
          <Modal.Content>
            <div className='modal__content'>
              {isLoading ? (
                <Loader active inline='centered'>
                  {t('admin.Loading')}
                </Loader>
              ) : (
                <Fragment>
                  <h3>Kursused ja tähtajad</h3>
                  <Button
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 'max-content',
                    }}
                    onClick={() => {
                      setCurrentStep(templateFlowSteps[1]);
                    }}
                  >
                    <i class='icon plus'></i>
                    Lisa või eemalda kursuseid
                  </Button>

                  {templateCourses &&
                    templateCourses.map((category) => {
                      return (
                        <AddCourseSegment
                          category={category}
                          editable={true}
                          editToggle={false}
                          globalBTNswitch={globalBTNswitch}
                          btnSwitcher={btnSwitcher}
                          templateID={template.id}
                        />
                      );
                    })}
                </Fragment>
              )}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={close}>Sulge</Button>
          </Modal.Actions>
        </Modal>
      );
      break;
    }
  }

  return currentModal;

  /* <TemplateDeadlinesSegment
            selectedCourses={selectedCourses}
            selectCourses={selectCourses}
            categories={categories}
          /> */
};

export default TemplateDetailsModal;
