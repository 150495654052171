export const ruTranslation = {
  landingPage: {
    title:
      'МЫ СЧИТАЕМ, ЧТО <span class="white-text">ЦИФРОВАЯ</span> ОНБОРДИНГ - <span class="white-text">БУДУЩЕЕ</span>.',
    subtitle:
      'Skillaby - веб-платформа для HR-специалистов, которая помогает упростить, автоматизировать и сделать процесс онбординга новых сотрудников более прозрачным.',
    userRegBtn:
      'ПОПРОБОВАТЬ <span style="color: #e5422d;">SKILLABY</span> БЕСПЛАТНО',
    userRegTitle: 'Какой тип демо-аккаунта вы хотите создать?',
    user: 'Учащийся',
    users: 'Менеджер по персоналу',
    userSubTitle:
      'Выберите демо для учащегося, если вы частное лицо и хотите видеть только обучающую среду.',
    usersSubTitle:
      'Выберите демо для менеджера по персоналу, если вы представляете компанию и хотите видеть среду управления HR. Обучающий вид также доступен вам.',
    userBtn: 'Выбрать',
    managerBtn: 'Выбрано',
    or: 'или',
    formName: 'Имя',
    formSurname: 'Фамилия',
    formCompany: 'Компания',
    emailAgreed: 'Я согласен получать электронные письма',
    Agreed: 'Я согласен с условиями',
    RegBtn: 'Я заказываю демо-среду',
    thankMsg:
      'Спасибо! Демо-среда создается, и мы скоро отправим более подробную информацию на ваш электронный адрес.',
    AccMainTitle: 'Что можно сделать с Skillaby',
    AccTitle1: 'Персональные образовательные пути',
    AccDesc1: `
<p>
<strong>Проблема</strong>: Новый сотрудник перегружен информацией. Необходимые информационные материалы разбросаны в нескольких разных местах и рассеяны среди других документов. Ему не хватает обзора того, как он продвигается с адаптацией.
</p>
<p>
<strong>Решение</strong>: Специалист по персоналу может создать образовательный путь для каждого сотрудника, который подавляет избыточный информационный шум. На персональном образовательном пути сотрудник видит только те материалы, которые важны для него. Они объединены в одном месте, организованы и запланированы. Это дает сотруднику ясность относительно его прогресса, показывает ему, что следующий шаг, а также позволяет ему лучше планировать свое время.
</p>
<p>
Образовательный путь остается у сотрудника. Это позволяет ему всегда вернуться к материалам, если ему потребуется освежить память.
</p>
`,
    AccTitle2: 'Отчеты и управление <i>соблюдением</i>',
    AccDesc2: `
<p>
<strong>Проблема:</strong> Специалист по персоналу не имеет обзора о том, как продвигается процесс онбординга сотрудника. Его беспокоит, завершены ли все важные действия, такие как ознакомление с инструкциями по безопасности. Находится ли новый сотрудник на правильном пути с онбордингом? Не было ли упущено чего-то важного?
</p>
<p>
<strong>Решение:</strong> Прогресс сотрудника на его образовательном пути непрерывно виден специалисту по персоналу. Поскольку шаги ориентированы на сроки, можно видеть, находится ли сотрудник на правильном пути с его онбордингом или нет.
</p>
<p>
Обзор завершенных материалов, таких как подтверждение сотрудником просмотра инструкций по безопасности, хранится в Skillaby и легко доступен в форме, позволяющей воспроизведение на бумаге (<i>управление соблюдением</i>).
</p>
`,

    AccTitle3: 'Тесты',
    AccDesc3: `
<p>
<strong>Проблема:</strong> Персонал действительно усвоил информацию в материалах или просто прокликал их?
</p>
<p>
<strong>Решение:</strong> Можно создавать тесты для образовательных материалов (или перед и между ними), чтобы убедиться, что сотрудник усвоил информацию. Тесты можно создавать в различных форматах, и вопросы теста могут поворачиваться для отображения разных вопросов каждый раз (исключая метод проб и ошибок при прохождении теста).
</p>
<p>
Если сотрудник не проходит тест до требуемого уровня, его можно направить обратно к материалам. Специалист по персоналу может просматривать все попытки и результаты тестов.
</p>
`,

    AccTitle4: 'Дизайн образовательных материалов',
    AccDesc4: `
<p>
<strong>Проблема:</strong> (a) Материалы, необходимые для онбординга, монотонны. Внимание сотрудников во время усвоения информации рассеяно, что делает онбординг скучным и изнурительным. Изученная информация не остается. (b) Отсутствуют цифровые формы онбординг-материалов (все распечатывается или в формате очных тренировок).
</p>
<p>
<strong>Решение:</strong> Помимо платформы, Skillaby предлагает услуги по проектированию образовательных материалов, где онбординг преобразуется в формат, предназначенный для самостоятельного электронного обучения. Это делает онбординг более эффективным и увлекательным для новых сотрудников благодаря увлекательным и инклюзивным образовательным материалам.
</p>
<p>
<a href='https://skillaby.talentlms.com/shared/start/key:ZGSDNHRK'>
	Ознакомьтесь с образцом образовательного материала Skillaby.
</a>
</p>
`,
    CourseTitle: 'Доступные курсы',
    RightsLink: 'Конфиденциальность и Условия использования',
    RightsTitle: 'Условия использования Skillaby',
    CloseBtn: 'Закрыть',
    Terms: `<p>
		<strong>Условия использования Skillaby</strong>
	</p>
	<br />
	<p>Условия использования действительны с 01.05.2023 года</p>
	<br />
	<p>
		Эти условия использования регулируют предоставление цифровой услуги по обучению цифровым навыкам, предоставляемой BCS Koolitus через среду Skillaby, направленную на помощь Клиенту более легко управлять индивидуальным обучением и облегчать планирование и реализацию профессионального развития физических лиц более удобно.
	</p>
	<h3>1. Определения</h3>
	<ul>
		<li>
			<strong>BCS Koolitus -</strong> BCS Koolitus AS (регистрационный код
			107230477, адрес Aia 7, Таллинн 10111).
		</li>
		<li>
			<strong>Клиент</strong> – физическое лицо, заключившее договор с BCS
			Koolitus на использование цифровой услуги по обучению цифровым навыкам.&nbsp;
		</li>
		<li>
			<strong>Skillaby Environment&nbsp;</strong>– облачная
			среда цифровой образовательной услуги, включающая обучающие материалы по цифровым навыкам (видео, аудио и текстовые материалы); интерактивные упражнения для закрепления усвоенного; возможность, в зависимости от пакета, сохранять собственные учебные материалы Клиента; возможность создавать персональные учебные пути из учебных материалов; возможность отслеживать прогресс обучения учащихся в завершении учебных материалов.
		</li>
		<li>
			<strong>Конечный пользователь</strong> – физическое лицо, которому Клиент предоставляет
			доступ к среде Skillaby.
		</li>
	</ul>
	<h3>2. Цифровая образовательная услуга по цифровым навыкам</h3>
	<ul>
		<li>
			Клиент имеет доступ к облачной цифровой образовательной услуге
			под названием Skillaby.
		</li>
		<li>
			Клиент имеет право предоставлять доступ к среде Skillaby
			своему сотруднику или другому лицу.
		</li>
		<li>
			Конечный пользователь имеет доступ к учебному пути, созданному Клиентом,
			и содержащимся в нем учебным материалам.
		</li>
		<li>
			Клиент имеет доступ к информации о завершении учебных наггетов и аналитике обучения
			конечных пользователей.
		</li>
		<li>Среда Skillaby доступна Клиенту 24/7.</li>
		<li>
			BCS Koolitus постоянно разрабатывает и улучшает среду Skillaby,
			включая банк обучающих наггетов, чтобы предоставить Клиенту и Конечному пользователю всё более качественные и разнообразные услуги.
		</li>
	</ul>
	<h3>3. Ценообразование</h3>
	<ul>
		<li>
			Стоимость услуги может быть рассчитана в соответствии с потребностями
			Клиента с использованием калькулятора цен Skillaby, который можно найти на веб-сайте Skillaby (
			<a href='https://skillaby.bcskoolitus.ee/'>
				https://skillaby.bcskoolitus.ee
			</a>
			/).
		</li>
		<li>
			BCS Koolitus выставляет счет Клиенту за предстоящий период не позднее
			начала нового периода.
		</li>
	</ul>
	<h3>4. Обработка персональных данных</h3>
	<ul>
		<li>
			Для создания доступа к услуге BCS Koolitus обрабатывает следующие персональные данные о Клиенте и Конечном пользователе: полное имя, адрес электронной почты, номер телефона и наименование компании.&nbsp;
		</li>
		<li>
			Для целей предоставления услуги BCS Koolitus обрабатывает данные, генерируемые в ходе предоставления услуги: курсы, назначенные Конечному пользователю; завершенные, незавершенные и текущие курсы; результаты тестов и упражнений, выполненных в Skillaby; последний вход Конечного пользователя; частота входа пользователя; время, которое пользователь проводит на платформе.
		</li>
		<li>
			Уполномоченным обработчиком персональных данных в процессе предоставления услуги является BCS Koolitus,
			<a href='mailto:kool@bcs.ee'>kool@bcs.ee</a>, телефон
			680 6880.
		</li>
		<li>
			Персональные данные хранятся в течение одного месяца после закрытия учетной записи пользователя, если иное не требуется законодательством.
		</li>
		<li>
			BCS Koolitus может передавать персональные данные третьим лицам, предоставляющим услуги BCS Koolitus, с которыми BCS Koolitus заключил договор и которые обязаны обеспечивать соответствующие меры защиты при обработке персональных данных. BCS Koolitus предоставляет персональные данные поставщику услуги платформы Skillaby environment.
		</li>
		<li>
			В соответствии с требованиями, установленными действующим законодательством, BCS Koolitus обеспечивает защиту персональных данных соответствующими техническими и организационными мерами для предотвращения несанкционированного доступа, неправомерной обработки или раскрытия, случайной потери, изменения или уничтожения. BCS Koolitus внедряет организационные, физические, информационные и другие необходимые меры безопасности для эффективной защиты персональных данных.&nbsp;
		</li>
		<li>
			Конфиденциальность и условия использования при использовании услуги предоставляются на веб-сайте BCS Koolitus AS: skillaby.bcskoolitus.ee/terms. Начиная использовать услугу, Клиент подтверждает, что он прочитал и согласен с вышеуказанными условиями.&nbsp;
		</li>
	</ul>
	<h3>5. Интеллектуальная собственность</h3>
	<ul>
		<li>
			Части услуги и учебные материалы, включенные в нее, являются интеллектуальной собственностью BCS Koolitus или третьих лиц, связанных с предоставлением услуги, и все права на них принадлежат им.
		</li>
		<li>
			Клиенту и Конечному пользователю запрещается без письменного согласия BCS Koolitus:&nbsp;
			<ul>
				<li>
					Скачивать, сохранять или предоставлять другому лицу вне цифровой среды обучения цифровым навыкам BCS Koolitus любое обучающее содержимое (учебные материалы, части образовательной среды и т. д.), за исключением контента, предназначенного для скачивания BCS Koolitus (файлы заданий, файлы резюме и т. д.);
				</li>
				<li>
					продавать, модифицировать или иным образом распространять содержимое услуги;
				</li>
				<li>
					использовать услугу для целей, не указанных в описании услуги.&nbsp;
				</li>
			</ul>
		</li>
		<li>
			Если Клиент или Конечный пользователь нарушает обязательство, упомянутое в предыдущем пункте Соглашения, BCS Koolitus имеет право требовать штраф в размере 10 000 евро за каждое нарушение.
		</li>
	</ul>
	<h3>6. Изменения в Условиях использования</h3>
	<ul>
		<li>
			BCS Koolitus может вносить изменения в условия использования в любое время, уведомляя
			вас не менее чем за 14 дней. Изменения вступают в силу через 14 дней после
			их публикации по веб-адресу
			skillaby.bcskoolitus.ee/terms. Точная дата вступления в силу
			изменений указывается отдельно на том же веб-сайте. Если вы
			продолжаете использовать среду Skillaby после вступления в силу изменений,
			вы будете считаться принявшими изменения. Если вы не согласны с новыми условиями, у вас есть право прекратить доступ
			к среде Skillaby.
		</li>
	</ul>
	<h3>7. Прекращение использования услуги</h3>
	<ul>
		<li>
			Клиент имеет право прекратить доступ к услуге в любое
			время, уведомив BCS Koolitus в письменной форме о желании прекратить
			использование услуги.
		</li>
		<li>
			BCS Koolitus имеет право прекратить доступ Клиента к среде Skillaby, уведомив Клиента в письменной форме за 30 календарных дней до этого. В соответствии с соглашением между сторонами возможно прекращение использования услуги с более коротким сроком уведомления.
		</li>
	</ul>`,
    AddMore: 'Больше будет добавлено в ближайшем будущем:',
    ModulesTitle: 'В рамках этой темы вы можете завершить следующие модули:',
    LoginTitle:
      'Если у вас уже есть доступ к Skillaby, вы можете войти на этой странице.',
    LoginError: 'Неправильное имя пользователя или пароль',
    LogBtn: 'Начать обучение!',
    ForgotPswd: 'Забыли пароль?',
    RecoveryTitle:
      'Введите свои данные, и инструкции по установке нового пароля будут отправлены на ваш адрес электронной почты.',
    RecoveryBtn: 'Отправить',
  },
  admin: {
    Greeting: 'Привет',
    ForgotPswd: 'Изменить пароль',
    Logout: 'Выйти',
    CookieConsent: `Начиная использовать сервис, пользователь подтверждает, что он осведомлен о условиях защиты интеллектуальной собственности, а также соглашается с условиями обработки и защиты персональных данных.`,
    CookiePrivacyBtn: 'Подробнее.',
    CookieCloseBtn: 'Понятно',
    UserTermsTitle: 'Условия обслуживания конечного пользователя',
    UserTermsAgree: 'Согласен',
    UserTermsDisagree: 'Не согласен',
    Loading: 'Загрузка...',
    ModuleDesc: 'Описание модуля',
    AddModuleText: 'Добавить модуль',
    JoinModuleText: 'Присоединиться к курсу',
    ModuleSteps: 'Шаги модуля',
    AddModule: 'Добавить модуль',
    DeleteModule: 'Удалить модуль',
    RemoveModule: 'Удалить модуль',
    SureNotif: 'Вы уверены?',
    RemoveText: 'Удалить',
    CurseLinkBtn: 'Открыть<span className="hide-mobile"> модуль</span>',
    InfoText: 'Информация',
    HideModuleBtn: 'Изменить<span className="hide-mobile"> модуль</span>',
    LearningPath: 'Мой учебный путь',
    LearningProgress: 'Прогресс обучения!',
    BranchReport: 'Отчет о ветви',
    AddUsers: 'Добавить пользователей',
    SureUserNotif: 'Это действие нельзя отменить. Вы абсолютно уверены?',
    Yes: 'Да',
    SelectAllBtn: 'Выбрать все',
    RemoveSelected: 'Удалить выбранные',
    DownloadReportText: 'Загрузить отчет о прогрессе пользователя',
    RemoveUser: 'Удалить',
    DownloadBeforeDelete: 'Загрузить перед удалением',
    UserReport: 'Отчет о пользователе',
    AddingBranchUser: 'Добавление пользователей в ветвь',
    AddingMethod: 'Выберите, как вы хотите добавить пользователей:',
    FirstMethod: 'По одному',
    SecondMethod: 'Импортировать из файла',
    AddUserBtn: 'Добавить',
    Back: 'Назад',
    Next: 'Далее',
    CourseAddingText: `Выберите учебный путь или курсы, которые вы хотите добавить установите для прохождения пользователя. Все остальные курсы остаются у пользователя для добровольного прохождения.`,
    CoursePathText: 'Учебный путь',
    RemoveAll: 'Удалить все',
    AddSuccessMsg: 'Импорт завершен. Вы можете закрыть окно.',
    AddErrorMsg:
      'Возникли проблемы при добавлении некоторых пользователей. Пожалуйста, проверьте данные и повторите попытку.',
    AddLoadingMsg:
      'Не закрывайте окно веб-браузера. Пользователи импортируются.',
    CloseWindow: 'Закрыть окно',
    DeadlineMsg: 'Выберите сроки',
    DeadTimePickerMsg: 'Бессрочно',
    ReadFromFile: 'Читать из файла',
    ImportUsersMsg: `Чтобы импортировать пользователей, <a href='import.xlsx'>загрузите шаблон таблицы</a> и добавьте соответствующие данные. Затем вы можете загрузить файл и подтвердить добавление пользователей,`,
    ContactInfo1: `<h3>У вас есть вопросы по поводу Skillaby?</h3> <p>Команда Skillaby готова помочь вам всем нашим знанием и усилиями!</p> <p>Не стесняйтесь писать нам по адресу <a href='mailto:skillaby@bcs.ee'>skillaby@bcs.ee</a> или звонить нам по телефону <a href='tel:+3726806880'>680 68 80</a>. </p>`,
  },
  PasswordChange: {
    MainTitle: 'Изменение пароля',
    Loader: 'Обработка...',
    FirstLogin: 'Пожалуйста, измените свой пароль после первого входа.',
    NewPassword: 'Пожалуйста, введите новый пароль',
    NewPasswordPlaceholder: 'Новый пароль',
    NewPasswordPlaceholderRepeat: 'Повторите новый пароль',
    PasswordSuccessMsg: 'Пароль изменен!',
    PasswordErrorMsg: 'Произошла ошибка, пожалуйста, повторите попытку.',
    ChangeBtn: 'Изменить',
    CancelBtn: 'Отмена',
  },
};
