import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import './style.css';

const ForgotPassword = () => {
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [response, setResponse] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const { t } = useTranslation();

  const handleChange = (e, { value }) => {
    setEmail(value);
  };

  const handleSubmit = () => {
    setRequestLoading(true);
    if (email && email.length > 0) {
      const credentialRequest = {};
      credentialRequest.email = email;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentialRequest),
      };
      fetch('/api/v1/password/forgot', requestOptions).then((res) => {
        setRequestLoading(false);
        if (res.status === 200) {
          setResponse('Päring saadetud!');
        } else if (res.status === 404) {
          setResponse('Kontot ei eksisteeri.');
        } else {
          setResponse('Midagi läks valesti. Palun proovi uuesti.');
        }
      });
    }
  };

  return (
    <div className='ui stackable grid container centered'>
      <div className='six wide column'>
        <div className='authentication-image'>
          <img alt='Skillaby' src='skillaby_L_bb.png' />
        </div>
      </div>
      <div className='one wide column hide-mobile'>&nbsp;</div>
      <div className='seven wide column'>
        <div className='forgot-password'>
          <Form className='forgot-password__form' onSubmit={handleSubmit}>
            <p className='white-text'>{t('landingPage.RecoveryTitle')}</p>
            <Form.Input
              onChange={handleChange}
              name='email'
              placeholder='E-mail'
              iconPosition='left'
              icon='mail'
              value={email}
            />
            <Button loading={requestLoading} color='blue' type='submit'>
              {t('landingPage.RecoveryBtn')}
            </Button>
            <br />
            <br />
            {response && <span className='white-text'>{response}</span>}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
