import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { Icon } from 'semantic-ui-react';
import './style.css';

const Splash = ({ user, quote, currentBranch }) => {
  const [hovered, setHovered] = useState(null);
  const [statsInitialized, setStatsInitialized] = useState(false);
  const [stats, setStats] = useState(null);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    if (user.isAdmin) {
      const branchId = user.branch.id;
      fetch(`/api/v1/branch/${branchId}/stats`)
        .then((response) => response.json())
        .then((response) => {
          setStats(response);
          setStatsInitialized(true);
        });
    } else {
      setStats(user.progress);
      setStatsInitialized(true);
    }
  }, [user.branch]);

  const drawChart = () => {
    const isBranchStats = 0 < stats.total;
    const currentLng = i18n.language;
    let data;

    if (currentLng === 'en') {
      data = [
        {
          title: 'Unpassed',
          value: stats.pending,
          color: '#fff',
          font: '#000',
        },
      ];
      if (stats.completed > 0) {
        data.push({
          title: 'Passed',
          value: stats.completed,
          color: '#000',
          font: '#fff',
        });
      }
      if (stats.incomplete > 0) {
        data.push({
          title: 'In progress',
          value: stats.incomplete,
          color: '#280096',
          font: '#fff',
        });
      }
    } else if (currentLng === 'ru') {
      data = [
        {
          title: 'Не пройдено',
          value: stats.pending,
          color: '#fff',
          font: '#000',
        },
      ];
      if (stats.completed > 0) {
        data.push({
          title: 'Пройдено',
          value: stats.completed,
          color: '#000',
          font: '#fff',
        });
      }
      if (stats.incomplete > 0) {
        data.push({
          title: 'Прогресс',
          value: stats.incomplete,
          color: '#280096',
          font: '#fff',
        });
      }
    } else {
      data = [
        {
          title: 'Läbimata',
          value: stats.pending,
          color: '#fff',
          font: '#000',
        },
      ];
      if (stats.completed > 0) {
        data.push({
          title: 'Läbitud',
          value: stats.completed,
          color: '#000',
          font: '#fff',
        });
      }
      if (stats.incomplete > 0) {
        data.push({
          title: 'Pooleli',
          value: stats.incomplete,
          color: '#280096',
          font: '#fff',
        });
      }
      data.push({
        isTitle: true,
        title: isBranchStats ? user.branch.name : 'Progress',
        value: 0,
      });
    }
    console.log(data);
    return (
      <PieChart
        onMouseOver={(_, index) => {
          setHovered(index);
        }}
        onMouseOut={() => {
          setHovered(undefined);
        }}
        segmentsStyle={(index) => ({
          transition: 'opacity .3s',
          cursor: 'pointer',
          opacity: index === hovered ? 0.5 : 1,
        })}
        data={data}
        radius={42}
        lineWidth={60}
        label={(props) => {
          const { x, y, dx, dy, dataEntry } = props;
          const title = dataEntry.isTitle
            ? dataEntry.title
            : isBranchStats
            ? `${dataEntry.title} (${Math.round(dataEntry.percentage)}%)`
            : `${dataEntry.title} (${dataEntry.value})`;
          return (
            <text
              x={x}
              y={y}
              dx={dataEntry.isTitle ? 0 : dx * 1.5}
              dy={dataEntry.isTitle ? 0 : dy * 1.5}
              dominant-baseline='central'
              text-anchor='middle'
              style={{
                fontSize: '4px',
                fill: dataEntry.font,
              }}
            >
              {title}
            </text>
          );
        }}
        animate
      />
    );
  };
  const skillabyLogo = 'skillaby_L_bb.png';
  const myFitnessLogo = '/myfitness/myfitness-log.png';

  return (
    <div className='splash'>
      <div className='ui stackable grid container'>
        <div className='six wide column hide-desktop'>
          <div className='splash-image'>
            {statsInitialized ? (
              drawChart()
            ) : (
              <img
                alt='Skillaby'
                src={`${
                  currentBranch === 'myfitness' ? myFitnessLogo : skillabyLogo
                }`}
              />
            )}
          </div>
        </div>
        <div className='ten wide column'>
          <div className='splash-content'>
            {user && (
              <div>
                <h1>{`${t('admin.Greeting')}, ${user.firstName}`}</h1>
                {quote && (
                  <div>
                    <h2>
                      <span
                        className={`bold-text quote-text ${
                          currentBranch === 'myfitness'
                            ? 'myfitness-text'
                            : 'white-text'
                        }`}
                      >
                        <Icon size='tiny' name='quote left' />
                        {quote.content}
                        <Icon size='tiny' name='quote right' />
                      </span>
                    </h2>
                    <span className='author'>&nbsp;–&nbsp;{quote.author}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='six wide column hide-mobile'>
          <div className='splash-image'>
            {statsInitialized ? (
              drawChart()
            ) : (
              <img
                alt='Skillaby'
                src={`${
                  currentBranch === 'myfitness' ? myFitnessLogo : skillabyLogo
                }`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Splash;
