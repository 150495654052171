import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button, Dropdown, Icon, Image } from 'semantic-ui-react';
import { changeLanguage } from '../../../translate';
import ChangePasswordModal from '../../Modal/ChangePasswordModal';
import './style.css';

const Menu = ({ user, setUser, showLoader }) => {
  const [passwordModalOpen, setPasswordModalOpen] = React.useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [totalCourseCount, setTotalCourseCount] = React.useState(null);
  const [currentBranch, setCurrentBranch] = React.useState(null);
  const [isMyfitness, setIsMyfitness] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem('selectedLanguage') || 'est',
  );
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      fetch(`/api/v1/course-count`)
        .then((response) => response.json())
        .then((response) => setTotalCourseCount(response));
    }
    if (user?.branches?.some((branch) => branch.name === 'myfitness')) {
      setCurrentBranch('myfitness');
    } else {
      setCurrentBranch(null);
    }

    const pathname = location.pathname;
    if (pathname === '/myfitness/') {
      setIsMyfitness(true);
    } else {
      setIsMyfitness(false);
    }

    if (pathname === '/login') {
      setIsMyfitness(false);
    }
  }, [user, location.pathname]);

  const logout = () => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
    };
    fetch('/api/v1/authentication/logout', requestOptions).then(() => {
      window.location.href = window.location.origin;
    });
  };
  const generateLinks = (user) => {
    let links = [];

    if (user) {
      links = [
        {
          name: `Minu õpirada`,
          path: `/opirada`,
          pathname: '/opirada',
        },
        {
          name: `Kõik moodulid`,
          path: `/kursused`,
          pathname: '/kursused',
        },
      ];
      if (user.isAdmin) {
        links.push(
          {
            name: 'Kasutajad',
            path: `/kasutajad`,
            pathname: '/kasutajad',
          },
          // hide templates page from navbar
          // {
          //   name: 'Õpirajad',
          //   path: `/opirajad`,
          //   pathname: '/opirajad',
          // },
        );
      }
      links.push({
        name: 'Küsin abi',
        path: `/kontakt`,
        pathname: '/kontakt',
      });
    } else {
      if (isMyfitness) {
        links.push(
          {
            name: 'Fitness Academy',
            path: '/',
            pathname: '/',
          },
          {
            name: 'Login',
            path: '/myfitness/',
            pathname: '',
          },
          {
            name: 'Help',
            path: '/',
            pathname: '/',
          },
        );
      } else {
        links = [
          {
            name: 'Skillaby',
            path: '/',
            pathname: '/',
          },
          {
            name: 'Login',
            path: '/login',
            pathname: '/login',
          },
          {
            name: 'Küsin abi',
            path: '/kontakt',
            pathname: '/kontakt',
          },
        ];
      }
    }

    return links;
  };

  const generateLinksEng = (user) => {
    let links = [];

    if (user) {
      links = [
        {
          name: `My learning path`,
          path: `/opirada`,
          pathname: '/opirada',
        },
        {
          name: `All modules`,
          path: `/kursused`,
          pathname: '/kursused',
        },
      ];
      if (user.isAdmin) {
        links.push(
          {
            name: 'Users',
            path: `/kasutajad`,
            pathname: '/kasutajad',
          },
          // hide templates page from navbar
          // {
          //   name: 'Learning paths',
          //   path: `/opirajad`,
          //   pathname: '/opirajad',
          // },
        );
      }
      links.push({
        name: "I'm asking for help",
        path: `/kontakt`,
        pathname: '/kontakt',
      });
    } else {
      if (isMyfitness) {
        links.push(
          {
            name: 'Fitness Academy',
            path: '/',
            pathname: '/',
          },
          {
            name: 'Login',
            path: '/myfitness/',
            pathname: '',
          },
          {
            name: 'Help',
            path: '/',
            pathname: '/',
          },
        );
      } else {
        links = [
          {
            name: 'Skillaby',
            path: '/',
            pathname: '/',
          },
          {
            name: 'Login',
            path: '/login',
            pathname: '/login',
          },
          {
            name: "I'm asking for help",
            path: '/kontakt',
            pathname: '/kontakt',
          },
        ];
      }
    }

    return links;
  };

  const generateLinksRus = (user) => {
    let links = [];

    if (user) {
      links = [
        {
          name: `Мой путь обучения`,
          path: `/opirada`,
          pathname: '/opirada',
        },
        {
          name: `Все модули`,
          path: `/kursused`,
          pathname: '/kursused',
        },
      ];
      if (user.isAdmin) {
        links.push(
          {
            name: 'Пользователи',
            path: `/kasutajad`,
            pathname: '/kasutajad',
          },
          // hide templates page from navbar
          // {
          //   name: 'Учебный путь',
          //   path: `/opirajad`,
          //   pathname: '/opirajad',
          // },
        );
      }
      links.push({
        name: 'Мне нужна помощь',
        path: `/kontakt`,
        pathname: '/kontakt',
      });
    } else {
      if (isMyfitness) {
        links.push(
          {
            name: 'Fitness Academy',
            path: '/',
            pathname: '/',
          },
          {
            name: 'Login',
            path: '/myfitness/',
            pathname: '',
          },
          {
            name: 'Help',
            path: '/',
            pathname: '/',
          },
        );
      } else {
        links = [
          {
            name: 'Skillaby',
            path: '/',
            pathname: '/',
          },
          {
            name: 'Авторизация',
            path: '/login',
            pathname: '/login',
          },
          {
            name: 'Мне нужна помощь',
            path: '/kontakt',
            pathname: '/kontakt',
          },
        ];
      }
    }

    return links;
  };

  const currentLng = i18n.language;
  let generatedLinksLang;
  if (currentLng === 'en') {
    generatedLinksLang = generateLinksEng(user);
  } else if (currentLng === 'ru') {
    generatedLinksLang = generateLinksRus(user);
  } else {
    generatedLinksLang = generateLinks(user);
  }

  const { pathname } = useLocation();
  const links = generatedLinksLang;

  const myFitnessLogo = '/myfitness/myfitness-log.png';
  const skillabyLogoBB = '/skillaby_S_bb.png';
  const skillabyLogoWW = '/skillaby_S_ww.png';

  const langOptions = [
    {
      key: 'en',
      text: 'English',
      value: 'en',
    },
    {
      key: 'est',
      text: 'Eesti',
      value: 'est',
    },
    {
      key: 'ru',
      text: 'Русский',
      value: 'ru',
    },
  ];
  const storedLanguage = localStorage.getItem('selectedLanguage');

  useEffect(() => {
    if (selectedLanguage) {
      changeLanguage(storedLanguage);
    } else {
      changeLanguage(selectedLanguage);
    }
  }, [user, location.pathname]);

  const handleLanguageChange = (_, { value: lang }) => {
    changeLanguage(lang);
    setSelectedLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
  };
  return (
    <Fragment>
      <div className='menu'>
        <div className='menu__logo'>
          {showLoader ? (
            <i className='ui active inline loader medium inverted' />
          ) : isMyfitness ? (
            <Link to={links[0].path}>
              <img
                className={`hide-desktop logo-big`}
                alt='Myfitness'
                src={myFitnessLogo}
              />
              <img
                className={`hide-mobile logo-big`}
                alt='Myfitness'
                src={myFitnessLogo}
              />
            </Link>
          ) : (
            <Link to={links[0].path}>
              <img
                className={`hide-desktop ${
                  currentBranch === 'myfitness' ? 'logo-big' : 'logo-small'
                }`}
                alt='Skillaby'
                src={
                  currentBranch === 'myfitness' ? myFitnessLogo : skillabyLogoWW
                }
              />
              <img
                className={`hide-mobile ${
                  currentBranch === 'myfitness' ? 'logo-big' : 'logo-small'
                }`}
                alt='Skillaby'
                src={
                  currentBranch === 'myfitness' ? myFitnessLogo : skillabyLogoBB
                }
              />
            </Link>
          )}
          {process.env.REACT_APP_ENV === 'TEST' && (
            <span className='landing__title bold-text white-text'>
              &nbsp;–&nbsp;TESTKESKKOND
            </span>
          )}
        </div>
        <div className='menu__content hide-mobile'>
          <div className='lang-dropdown'>
            <Dropdown
              value={selectedLanguage}
              fluid
              selection
              options={langOptions}
              onChange={handleLanguageChange}
            />
          </div>
          {links.map((link) => (
            <Link
              key={link.name}
              to={link.path}
              className={`
              ${
                isMyfitness
                  ? 'menu__content-item-myfitness'
                  : 'menu__content-item'
              }
              ${
                currentBranch === 'myfitness'
                  ? 'menu__content-item-myfitness'
                  : 'menu__content-item'
              } ${pathname === link.pathname && 'menu__content-item--active'} ${
                pathname === link.pathname &&
                currentBranch === 'myfitness' &&
                'menu__content-item-myfitness--active'
              }`}
            >
              {link.name}
            </Link>
          ))}
          {user && (
            <span
              style={{ minWidth: '150px', margin: 0 }}
              className={
                currentBranch === 'myfitness'
                  ? 'menu__content-item-myfitness'
                  : 'menu__content-item'
              }
            >
              <Dropdown
                fluid
                floating
                icon={null}
                trigger={
                  <span
                    style={
                      currentBranch === 'myfitness' ? { color: '#312373' } : {}
                    }
                  >
                    <Image src={user.avatar} avatar spaced alt='' />
                    {user.firstName}
                    &nbsp;
                    <Icon name='angle down' />
                  </span>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setPasswordModalOpen(true)}>
                    <div className='menu__dropdown-item'>
                      {t('admin.ForgotPswd')}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout}>
                    <div className='menu__dropdown-item'>
                      {t('admin.Logout')}
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          )}
        </div>
        <div className='menu__content hide-desktop'>
          <Button
            color='orange'
            className='menu__content-btn'
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            icon
          >
            {mobileMenuOpen ? (
              <Icon name='close' inverted />
            ) : (
              <Icon name='bars' inverted />
            )}
          </Button>
        </div>
      </div>
      {mobileMenuOpen && (
        <div className='menu__content-mobile'>
          {links.map((link) => (
            <Link
              onClick={() => setMobileMenuOpen(false)}
              key={link.name}
              to={link.path}
              className={`menu__content-item ${
                pathname === link.pathname && 'menu__content-item--active'
              }`}
            >
              {link.name}
            </Link>
          ))}
          {user && (
            <Button onClick={logout} className='menu__content-item'>
              {t('admin.Logout')}
            </Button>
          )}
        </div>
      )}
      <ChangePasswordModal
        user={user}
        setUser={setUser}
        isOpen={passwordModalOpen || (user && user.passwordChangeRequired)}
        closeModal={() => setPasswordModalOpen(false)}
      />
    </Fragment>
  );
};

export default Menu;
