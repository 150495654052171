import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Label, Segment } from 'semantic-ui-react';
import './style.css';
//import TemplateDeadlineEdit from "../../../Segment/TemplateDeadlineEdit/index";

const AddCourseSegment = ({
  category,
  selectedCourses,
  selectCourses,
  deselectCourses,
  templateCoursesMatch, // is an array of courses that are in a (currently selected) template
  templateNameList,
  editable,
  btnSwitcher,
  //setGlobalBTNswitch,
  globalBTNswitch,
  templateID,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [allToggled, setAllToggled] = React.useState(false);
  const [matchWithTemplate, setMatchWithTemplate] = React.useState({});
  const [deadlineData, setDeadlineData] = React.useState({});
  const [showCourse, setShowCourse] = React.useState({});
  const { t } = useTranslation();

  useEffect(() => {
    console.log(selectedCourses);
    console.log(deadlineData);
    console.log(deadlineData['195'] == null);
    console.log(!!deadlineData['195']);
    console.log(typeof deadlineData['195'] == 'undefined');

    const allCourseIds = category.courses.map((course) => course.id);

    if (!!templateID) templateID = null;

    //console.log(templateID)
    if (typeof templateCoursesMatch !== 'undefined') {
      if (Object.keys(templateCoursesMatch).length !== 0) {
        //console.log(templateCoursesMatch)

        templateCoursesMatch.forEach((templateCat) => {
          if (templateCat.id == category.id) {
            //console.log("Match!", templateCat.name)

            setIsOpen(true);
            let idCollector = {};

            allCourseIds.forEach((generalCourseID) => {
              idCollector[generalCourseID] = false;
            });

            templateCat.courses.forEach((templateCourse) => {
              idCollector[templateCourse.id] = true;
            });

            let newSelected = {};

            templateCat.courses.forEach((templateCourse) => {
              newSelected[templateCourse.id] = null;
            });

            console.log(newSelected);
            setMatchWithTemplate(idCollector);
          }
        });
      }
    }

    const courseIds = category.courses.map((course) => course.id);
    let obj = {};
    courseIds.forEach((cID) => {
      obj[cID] = true;
    });

    setShowCourse({ ...obj });

    if (!!selectCourses) {
      const allSelected = courseIds.every((courseId) =>
        Object.keys(selectedCourses).includes(courseId),
      );
      setAllToggled(allSelected);
    }
  }, [
    selectCourses /* , overrideIsOpen, isOpen, setIsOpen, category, selectedCourses */,
  ]);

  const toggleAll = (e) => {
    e.stopPropagation();
    const courseIds = category.courses.map((course) => course.id);
    if (!allToggled) {
      const courseIdObject = category.courses.reduce(
        (acc, course) => ((acc[course.id] = null), acc),
        {},
      );
      selectCourses(courseIdObject);
    } else {
      deselectCourses(courseIds);
    }
  };

  const selectCourse = async (e, courseId) => {
    //if (e) e.stopPropagation();
    console.log('adding course');
    matchWithTemplate[courseId] = true;
    await selectCourses({ [courseId]: null });
  };

  const deselectCourse = (e, courseId) => {
    //e.stopPropagation();
    console.log('removing course');
    delete matchWithTemplate[courseId];
    deselectCourses([courseId]);
  };

  const updateDeadline = async (
    templateName,
    selectedCourses,
    par_templateID,
    courseID,
    days,
  ) => {
    //setIsLoading(true);//
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    };
    await fetch(`/api/v1/template/update/deadline/${par_templateID}`, {
      ...requestOptions,
      body: JSON.stringify({ name: templateName, courseID, days }),
    })
      .then((res) => {
        console.log(res);
        /* if (res.ok) {
          return res.json();
        }
        throw res.json(); */
      })
      .catch((error) => {
        console.log('found error', error);
      });
  };

  const removeCourseFromTemplate = async (par_templateID, courseID) => {
    par_templateID = Number(par_templateID);
    courseID = Number(courseID);
    console.log('par_templateID', par_templateID, 'courseID', courseID);

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    };
    await fetch(`/api/v1/template/course/remove/${par_templateID}`, {
      ...requestOptions,
      body: JSON.stringify({ courseID }),
    })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log('found error', error);
      });
  };

  return (
    // check if templateNameList is not undefined, and then see if current category is inside it
    <Segment.Group
      style={{
        backgroundColor:
          !!templateNameList && templateNameList[category.name]
            ? '#72AAFF'
            : 'initial',
      }}
      className='category-segment'
    >
      <Segment
        style={{
          backgroundColor:
            !!templateNameList && templateNameList[category.name]
              ? '#C5DCFF'
              : 'initial',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h5 className='category-segment__title'>
          <img
            className='category-segment__item-logo'
            alt={category.name}
            src={category.icon}
          />
          {category.name}
          <span className='category-segment__item-caret'>
            {selectCourses && (
              <Button size='mini' onClick={(e) => toggleAll(e)}>
                {allToggled ? t('admin.RemoveAll') : t('admin.SelectAllBtn')}
              </Button>
            )}
            <span onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <i className='angle up icon' />
              ) : (
                <i className='angle down icon' />
              )}
            </span>
          </span>
        </h5>
      </Segment>
      {isOpen &&
        category.courses.map((course) =>
          showCourse[course.id] ? (
            <Segment
              key={`course-${course.id}`}
              className='add-course-segment__item'
              id={course.id + '-courseItem'}
            >
              <div className='add-course-segment__item-main'>
                {course.name}

                <div
                  style={{
                    display: 'flex',
                    padding: '0 10px 0 0',
                    visibility: !!editable ? 'visible' : 'hidden',
                  }}
                >
                  <Label
                    style={{
                      width: '85px',
                      margin: '0 12px',
                      textAlign: 'center',
                      visibility:
                        !!globalBTNswitch && !globalBTNswitch[course.id]
                          ? 'visible'
                          : 'hidden',
                    }}
                  >
                    {!!deadlineData[course.id]
                      ? deadlineData[course.id] + ' päeva'
                      : typeof deadlineData[course.id] !== 'undefined' &&
                        deadlineData[course.id] == null
                      ? 'Tähtajatu'
                      : course.deadline
                      ? course.deadline + ' päeva'
                      : 'Tähtajatu'}
                    {/* see if deadlinedata exists, if it does, show the days from it. If it does exist, but is null, show "Tähtajatu", If it doesn't exist, show the days from course.deadline, if that doesn't exist, show "Tähtajatu" */}
                  </Label>
                  {!!globalBTNswitch && globalBTNswitch[course.id] ? (
                    <div style={{ display: 'flex' }}>
                      <div className='course-deadline-segment__item-menu'>
                        <Form.Input
                          id={course.id + '-inputField'}
                          onChange={(a, { value }) => {
                            console.log(value);
                          }}
                          name='templateName'
                          placeholder='Periood (päevades)'
                        />
                      </div>
                      <Button
                        style={{ display: 'flex', alignItems: 'center' }}
                        size='mini'
                        color='red'
                        icon='check'
                        content='Salvesta'
                        basic
                        onClick={() => {
                          let obj = globalBTNswitch;
                          obj[course.id] = !obj[course.id];
                          btnSwitcher(obj);
                          const days = document.getElementById(
                            course.id + '-inputField',
                          ).value;
                          console.log(days === '');

                          let deadlineObj = deadlineData;

                          const regexDetect = /\b.*tähtajatu.*\b/i;

                          if (
                            days == null ||
                            days == 0 ||
                            days == '' ||
                            regexDetect.test(days)
                          ) {
                            console.log('this runs');
                            deadlineObj[course.id] = null;
                            updateDeadline(
                              category.name,
                              selectedCourses,
                              templateID,
                              course.id,
                              null,
                            );
                          } else if (!!days && days > 0) {
                            deadlineObj[course.id] = days;
                            updateDeadline(
                              category.name,
                              selectedCourses,
                              templateID,
                              course.id,
                              days,
                            );
                          }

                          setDeadlineData({ ...deadlineObj });
                        }}
                      />
                      <Button
                        style={{ display: 'flex', alignItems: 'center' }}
                        size='mini'
                        color='red'
                        icon='trash'
                        onClick={() => {
                          let currentCourseElement = document.getElementById(
                            course.id + '-courseItem',
                          );

                          let templateElement =
                            currentCourseElement.parentElement;

                          if (templateElement.childElementCount == 2) {
                            let obj = showCourse;
                            obj[course.id] = false;
                            setShowCourse({ ...obj });
                            try {
                              templateElement.remove(); // not by the standards, but works for now
                            } catch (error) {
                              console.log('Found error:', error);
                            }
                          } else {
                            let obj = showCourse;
                            obj[course.id] = false;
                            setShowCourse({ ...obj });
                          }

                          removeCourseFromTemplate(templateID, course.id);
                        }}
                      />
                    </div>
                  ) : (
                    <Button
                      size='mini'
                      color='red'
                      icon='edit'
                      content='Redigeeri'
                      basic
                      onClick={() => {
                        let obj = globalBTNswitch;
                        obj[course.id] = !obj[course.id];
                        btnSwitcher(obj);
                      }}
                    />
                  )}
                </div>
              </div>
              {selectedCourses && (
                <div className='category-segment__item-menu'>
                  {!Object.keys(selectedCourses).includes(course.id) ? (
                    <Button
                      size='mini'
                      onClick={async (e) => await selectCourse(e, course.id)}
                    >
                      {t('landingPage.userBtn')}
                    </Button>
                  ) : (
                    <Button
                      size='mini'
                      onClick={(e) => deselectCourse(e, course.id)}
                    >
                      {t('admin.RemoveUser')}
                    </Button>
                  )}
                </div>
              )}
            </Segment>
          ) : null,
        )}
    </Segment.Group>
  );
};

export default AddCourseSegment;
