import React, { Fragment } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import './style.css';

const TemplateDeadlineSegment = (
  {
    course,
    selectCourse,
    selectedCourses,
    icon,
  }) => (
  <Segment
    key={`course-${course.id}`}
    className="course-deadline-segment__item">
    <div className="course-deadline-segment__item-main">
      {icon && (
        <img className="course-deadline-segment__item-logo"
             alt={course.name}
             src={icon}/>
      )}
      {course.name}
    </div>
    {selectedCourses && Object.keys(selectedCourses).includes(course.id) && (
      <div className="course-deadline-segment__item-menu">
        <Form.Input
          onChange={(a, { value }) => selectCourse(null, course.id, value)}
          name="templateName"
          placeholder="Periood (päevades)"
        />
      </div>
    )}
  </Segment>
);
//
const TemplateDeadlinesSegment = (
  {
    categories,
    selectCourses,
    selectedCourses,
    selectNewCourseDate
  }
) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const selectCourse = (e, courseId, weeks) => {
    console.log(selectedCourses)
    if (e) e.stopPropagation();
    const value = weeks === 0 ? null : weeks;
    selectCourses({ [courseId]: value });
    console.log(value)
  };

  return (
    
    <Fragment>
      {categories.filter((category) => !!category.courses
      .find((course) => Object.keys(selectedCourses).indexOf(course.id) > -1))
      .map((category) => {
        const categoryCourses = category.courses.filter((course) =>
          Object.keys(selectedCourses).indexOf(course.id) > -1);
        const isSingle = categoryCourses.length === 1;
        return (
          <Segment.Group className="course-deadline-segment">
            {isSingle
              ? (<TemplateDeadlineSegment
                course={categoryCourses[0]}
                selectCourse={!!selectNewCourseDate ? selectNewCourseDate : selectCourse}
                selectedCourses={selectedCourses}
                icon={category.icon}
              />)
              : (
                <Segment>
                  <h5 className="course-deadline-segment__title">
                    <img className="course-deadline-segment__item-logo"
                         alt={category.name}
                         src={category.icon}/>
                    <span onClick={() => setIsOpen(!isOpen)}>
                      {category.name}
                    </span>
                  </h5>
                </Segment>)}
            {isOpen && !isSingle && categoryCourses
            .map((course) => (
              <TemplateDeadlineSegment
                course={course}
                selectCourse={!!selectNewCourseDate ? selectNewCourseDate : selectCourse}
                selectedCourses={selectedCourses}
              />)
            )}
          </Segment.Group>
        );
      })}
    </Fragment>
  );
};

export default TemplateDeadlinesSegment;
