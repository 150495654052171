import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Divider, Grid, Header, Image } from 'semantic-ui-react';
import Footer from '../../components/Layout/Footer';
import Menu from '../../components/Layout/Menu';
import LandingForm from './landingForm';
import LandingModal from './landingModal';
import LandingPossibilities from './landingPossibilities';
import './style.css';

const CourseCard = ({ getCategoryInfo, category, dark }) => {
  if (dark) {
    return (
      <div className='course__card course__card--black'>
        <div className='bold-text'>{category}</div>
        <div className='course__card-footer'>
          <img alt={category} src='skillaby_S_wo.png' />
        </div>
      </div>
    );
  }
  return (
    <button onClick={getCategoryInfo} className='course__card'>
      <div className='bold-text'>{category.name}</div>
      <div className='course__card-footer'>
        <img alt={category.name} src='skillaby_S_wo.png' />
      </div>
    </button>
  );
};

const Landing = ({ user }) => {
  const [showPricing, setShowPricing] = React.useState(false);
  const [categories, setCategories] = React.useState(null);
  const [tbdCategories, setTbdCategories] = React.useState(null);
  const [categoryInfo, setCategoryInfo] = React.useState(null);
  const { t } = useTranslation();
  React.useEffect(() => {
    if (!categories) {
      fetch('/api/v1/category/list')
        .then((res) => res.json())
        .then((data) => {
          const { active, tbd } = data;
          setCategories(active);
          setTbdCategories(tbd);
        });
    }
  });
  const getCategoryInfo = (categoryId) => {
    fetch(`/api/v1/category/${categoryId}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.courses || data.customInfo) {
          setCategoryInfo(data);
        }
      });
  };

  return (
    <div className='landing'>
      <header className='header'>
        <Menu user={user} inverted />
      </header>
      <div className='container'>
        <Container>
          <Divider section hidden />

          <Grid stackable columns={2}>
            <Grid.Column mobile={6} tablet={6} computer={6}>
              <Image
                src='skillaby_L_bb.png'
                size='massive'
                verticalAlign='middle'
                alt='Skillaby logo'
              />
            </Grid.Column>
            <Grid.Column mobile={6} tablet={10} computer={10}>
              <p
                className='landing__cta bold-text'
                dangerouslySetInnerHTML={{
                  __html: t('landingPage.title'),
                }}
              />
              <p className='landing__description landing-description-tk white-text '>
                {t('landingPage.subtitle')}
              </p>
            </Grid.Column>
          </Grid>
        </Container>

        <Divider section hidden />
        <Divider section hidden />

        <LandingForm />

        <Divider section hidden />
        <Divider section hidden />

        <Container>
          <Header as='h2'>{t('landingPage.AccMainTitle')}</Header>
        </Container>
        <Container className='landing__possibilities'>
          <LandingPossibilities />
        </Container>

        <Divider section hidden />

        {categories && categories.length > 0 && (
          <Fragment>
            <div className='air' />
            <div className='ui stackable grid container'>
              <div className='sixteen wide column'>
                <span className='landing__title white-text bold-text'>
                  {t('landingPage.CourseTitle')}
                </span>
              </div>
            </div>
            <div className='ui three column grid stackable container'>
              {categories
                .sort((a, b) => a.id - b.id)
                .map((category) => (
                  <div key={`category-${category.id}`} className='column'>
                    <CourseCard
                      getCategoryInfo={() => getCategoryInfo(category.id)}
                      category={category}
                    />
                  </div>
                ))}
            </div>
          </Fragment>
        )}
        {tbdCategories && tbdCategories.length > 0 && (
          <Fragment>
            <div className='air' />
            <div className='ui stackable grid container'>
              <div className='sixteen wide column'>
                <div className='landing__heading bold-text'>
                  {t('landingPage.AddMore')}
                </div>
              </div>
            </div>
            <div className='ui three column grid stackable container'>
              {tbdCategories.map((category) => (
                <div className='column'>
                  <CourseCard category={category.name} dark />
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </div>
      <div className='air' />

      <div className='air hide-mobile' />
      <div className='air hide-mobile' />
      <LandingForm />
      <div className='air' />
      <Footer />
      {categoryInfo && (
        <LandingModal
          close={() => setCategoryInfo(null)}
          category={categoryInfo}
        />
      )}
    </div>
  );
};

export default Landing;
