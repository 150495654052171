import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, Segment } from 'semantic-ui-react';
import { getUrlPattern } from '../../../helpers/helpers';
import './style.css';

const CategorySegment = ({
  user,
  category,
  getCourses,
  getCourseDetails,
  courseLoading,
  showAll,
  setModalCategory,
  joinCourse,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [joinAllLoading, setJoinAllLoading] = React.useState(false);
  const { t } = useTranslation();

  if (!category.courses) {
    return null;
  }

  const urlPattern = getUrlPattern(user);
  const openCourse = (courseId) => {
    window.open(
      `https://${urlPattern}.talentlms.com/learner/course/id:${courseId}`,
      '_blank',
    );
  };
  const changeCourse = (courseId) => {
    window.open(
      `https://${urlPattern}.talentlms.com/trainer/course/id:${courseId}`,
      '_blank',
    );
  };

  const showJoinAll = (category) => {
    if (!showAll) return false;
    if (!joinCourse) return false;
    if (user.isAdmin) return false;
    return category.courses.find((course) => !course.hasCourse);
  };

  const joinAllCourses = () => {
    setIsOpen(true);
    setJoinAllLoading(true);
    category.courses.forEach((course) => {
      if (!course.hasCourse) {
        joinCourse(course.id);
      }
    });
    getCourses();
    setJoinAllLoading(false);
  };

  const segmentClass = !showAll && 'path-segment';
  let segmentsClasses = 'category-segment';
  let linkClass = 'link';
  if (segmentClass) {
    linkClass = `${linkClass} link--dark`;
    segmentsClasses = `${segmentsClasses} path-segments`;
    const isCompleted = !category.courses.find((course) => !course.isCompleted);
    if (isCompleted) {
      segmentsClasses = `${segmentsClasses} path-segments--green`;
    } else {
      const deadlinePassed =
        !isCompleted &&
        category.courses.find(
          (course) => course.deadline && new Date(course.deadline) < new Date(),
        );
      if (deadlinePassed) {
        segmentsClasses = `${segmentsClasses} path-segments--red`;
      }
    }
  } else {
    linkClass = `${linkClass} blue-text`;
  }

  const getCategoryDeadlineLabel = (courses) => {
    if (courses.find((course) => course.deadline)) {
      const minDate = new Date(
        Math.min(
          ...courses
            .filter((course) => course.deadline)
            .map((course) => new Date(course.deadline)),
        ),
      );
      const today = new Date();
      let color;
      const coursesCompleted = !courses.find((course) => !course.isCompleted);
      if (coursesCompleted) color = 'green';
      if (minDate < today) color = 'red';
      const dateStr = minDate.toLocaleDateString().replaceAll('/', '.');
      return <Label color={color}>{dateStr}</Label>;
    }
    return null;
  };

  const getCourseDeadlineLabel = (course) => {
    if (course.deadline) {
      let color;
      if (course.isCompleted) color = 'green';
      const date = new Date(course.deadline);
      if (date < new Date()) color = 'red';
      const dateStr = date.toLocaleDateString().replaceAll('/', '.');
      return <Label color={color}>{dateStr}</Label>;
    }
    return null;
  };

  return (
    <Segment.Group className={segmentsClasses}>
      <Segment
        className={segmentClass || ''}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className='category-segment__title'>
          <img
            className='category-segment__item-logo'
            alt={category.name}
            src={category.icon}
          />
          {category.name}
          <span className='category-segment__item-caret'>
            {getCategoryDeadlineLabel(category.courses)}
            {showJoinAll(category) && (
              <Button
                size='mini'
                loading={joinAllLoading}
                onClick={(e) => {
                  e.stopPropagation();
                  joinAllCourses();
                }}
              >
                <i className='plus icon' />
                &nbsp;{t('admin.JoinModuleText')}
              </Button>
            )}
            {category.isEditable && (
              <Button
                size='mini'
                onClick={(e) => {
                  e.stopPropagation();
                  setModalCategory(category.id);
                }}
              >
                <i className='plus icon' />
                &nbsp;{t('admin.AddModuleText')}
              </Button>
            )}
            {isOpen ? (
              <i className='angle up icon' />
            ) : (
              <i className='angle down icon' />
            )}
          </span>
        </span>
      </Segment>
      {isOpen &&
        category.courses.map((course) => (
          <Segment
            disabled={course.isDisabled}
            key={`course-${course.id}`}
            className='category-segment__item'
          >
            <div
              onClick={() =>
                getCourseDetails &&
                getCourseDetails(
                  course.id,
                  course.isDisabled,
                  category.isEditable,
                )
              }
              className='category-segment__item-main'
            >
              <div className='category-segment__item-logo'>
                {courseLoading === course.id ? (
                  <div className='ui active inline loader tiny' />
                ) : (
                  !showAll && (
                    <Icon
                      name={
                        course.isCompleted
                          ? 'check circle outline'
                          : 'circle outline'
                      }
                      color={course.isCompleted ? 'green' : 'grey'}
                    />
                  )
                )}
              </div>
              {course.name}
            </div>
            <div className='category-segment__item-menu'>
              {getCourseDeadlineLabel(course)}
              {getCourseDetails && (
                <span
                  className={linkClass}
                  onClick={() =>
                    getCourseDetails(
                      course.id,
                      course.isDisabled,
                      category.isEditable,
                    )
                  }
                >
                  <i className='info circle icon' />
                  {t('admin.InfoText')}
                </span>
              )}
              {changeCourse && category.isEditable && (
                <span
                  className={course.isDisabled ? 'disabled' : linkClass}
                  onClick={() => changeCourse(course.id)}
                >
                  <i className='arrow alternate circle right icon' />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('admin.HideModuleBtn'),
                    }}
                  />
                </span>
              )}
              {getCourseDetails &&
                !category.isEditable &&
                (!showAll || course.hasCourse) &&
                !course.isDisabled && (
                  <span
                    className={course.isDisabled ? 'disabled' : linkClass}
                    onClick={() => !course.isDisabled && openCourse(course.id)}
                  >
                    <i className='arrow alternate circle right icon' />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('admin.CurseLinkBtn'),
                      }}
                    />
                  </span>
                )}
            </div>
          </Segment>
        ))}
    </Segment.Group>
  );
};

export default CategorySegment;
