import React from 'react';
import './style.css';
import Error from '../../components/Core/Error';
import { Menu } from 'semantic-ui-react';
import Footer from '../../components/Layout/Footer';


const ErrorPage = ({
                     user,
                     error,
                   }) => {
  return (
    <div className="wize">
      <header className="header">
        <Menu user={user}/>
      </header>
      <Error errorCode={error}/>
      <Footer/>
    </div>
  );
};

export default ErrorPage;
