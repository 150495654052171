import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Popup, Segment } from 'semantic-ui-react';
import '../style.css';

const UserActionsSegment = ({
  toggleSelectAll,
  currentBranch,
  openImportModal,
  removeUsers,
  checked,
  selected,
  loading,
}) => {
  const { t } = useTranslation();
  const syncDirectoUsers = async () => {
    try {
      const response = await fetch('/api/v1/directo');
      const data = await response.json();
      if (data) {
        alert('directo users synced!');
      }
    } catch (err) {
      console.error('Error while syncing users:', err);
    }
  };

  return (
    <Segment className='users-segment__item users-segment__item--static'>
      <div className='users-segment__item--static-checkbox'>
        <Checkbox
          checked={checked}
          label={t('admin.SelectAllBtn')}
          onChange={toggleSelectAll}
        />
      </div>
      <div>
        <Button color='blue' size='mini' onClick={syncDirectoUsers}>
          <span className='hide-mobile'>
            <i class='sync alternate icon' />
            Directo
          </span>
          <span className='hide-desktop'>Progress</span>
        </Button>
        <a
          href={`https://${currentBranch.name}-skillaby.talentlms.com/reports/index`}
          target='_blank'
          rel='noreferrer'
        >
          <Button color='blue' size='mini'>
            <span className='hide-mobile'>
              <i className='icon chart pie' />
              {t('admin.LearningProgress')}
            </span>
            <span className='hide-desktop'>Progress</span>
          </Button>
        </a>
        <a
          href={`https://${
            currentBranch.name
          }-skillaby.talentlms.com/reports/systemtoexcel/token:${new Date().getTime()}`}
          target='_blank'
          rel='noreferrer'
        >
          <Button color='blue' size='mini'>
            <span className='hide-mobile'>
              <i className='icon file alternate outline' />
              {t('admin.BranchReport')}
            </span>
            <span className='hide-desktop'>Raport</span>
          </Button>
        </a>
        <Button
          size='mini'
          color='blue'
          onClick={openImportModal}
          disabled={loading || selected}
        >
          <i className='icon users' />
          {t('admin.AddUsers')}
        </Button>
        {selected && (
          <Popup
            trigger={
              <Button
                disabled={loading}
                size='mini'
                color='red'
                icon='remove'
                content={t('admin.RemoveSelected')}
              />
            }
            content={
              <div>
                <b>{t('admin.SureUserNotif')}</b>
                <br />
                <Button fluid size='mini' color='red' onClick={removeUsers}>
                  <i className='icon remove' />
                  {t('admin.Yes')}
                </Button>
              </div>
            }
            on='click'
            position='top center'
          />
        )}
      </div>
    </Segment>
  );
};

export default UserActionsSegment;
