import React from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.css';

const DateSelect = ({ minDate, value, onChange }) => {
  return (
    <Calendar
      locale="et-ET"
      value={value}
      minDate={minDate}
      onChange={(val) => onChange(val)}
    />
  );
};

export default DateSelect;
