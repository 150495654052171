import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Grid, Icon } from 'semantic-ui-react';
import './style.css';

const LandingPossibilities = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <Accordion
      className='white-text'
      exclusive={false}
      defaultActiveIndex={[0, 2]}
    >
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
        as='h3'
        className='white-text ui'
      >
        <Icon name='angle down' size='large' />
        {t('landingPage.AccTitle1')}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <Grid stackable columns={2}>
          <Grid.Column mobile={16} tablet={11} computer={11}>
            <p
              dangerouslySetInnerHTML={{
                __html: t('landingPage.AccDesc1'),
              }}
            />
          </Grid.Column>
          <Grid.Column
            textAlign='center'
            verticalAlign='middle'
            only='computer tablet'
            tablet={5}
            computer={5}
          >
            <Icon name='road' size='massive' />
          </Grid.Column>
        </Grid>
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 1}
        index={1}
        onClick={handleClick}
        as='h3'
        className='white-text ui'
      >
        <Icon name='angle down' size='large' />
        <span
          dangerouslySetInnerHTML={{
            __html: t('landingPage.AccTitle2'),
          }}
        />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <Grid stackable columns={2}>
          <Grid.Column mobile={16} tablet={11} computer={11}>
            <p
              dangerouslySetInnerHTML={{
                __html: t('landingPage.AccDesc2'),
              }}
            />
          </Grid.Column>
          <Grid.Column
            textAlign='center'
            verticalAlign='middle'
            only='computer tablet'
            tablet={5}
            computer={5}
          >
            <Icon name='chart bar' size='massive' />
          </Grid.Column>
        </Grid>
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 2}
        index={2}
        onClick={handleClick}
        as='h3'
        className='white-text ui'
      >
        <Icon name='angle down' size='large' />
        {t('landingPage.AccTitle3')}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 2}>
        <Grid stackable columns={2}>
          <Grid.Column mobile={16} tablet={11} computer={11}>
            <p
              dangerouslySetInnerHTML={{
                __html: t('landingPage.AccDesc3'),
              }}
            />
          </Grid.Column>
          <Grid.Column
            textAlign='center'
            verticalAlign='middle'
            only='computer tablet'
            tablet={5}
            computer={5}
          >
            <Icon name='clipboard check' size='massive' />
          </Grid.Column>
        </Grid>
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 3}
        index={3}
        onClick={handleClick}
        as='h3'
        className='white-text ui'
      >
        <Icon name='angle down' size='large' />
        {t('landingPage.AccTitle4')}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 3}>
        <Grid stackable columns={2}>
          <Grid.Column mobile={16} tablet={11} computer={11}>
            <p
              dangerouslySetInnerHTML={{
                __html: t('landingPage.AccDesc4'),
              }}
            />
          </Grid.Column>
          <Grid.Column
            textAlign='center'
            verticalAlign='middle'
            only='computer tablet'
            tablet={5}
            computer={5}
          >
            <Icon name='paint brush' size='massive' />
          </Grid.Column>
        </Grid>
      </Accordion.Content>
    </Accordion>
  );
};

export default LandingPossibilities;
