import { Button, Segment } from 'semantic-ui-react';
import '../style.css';

const SingleUserSegment = ({openImportModal, branchLoading, removedUsers}) => (
  <Segment className="users-segment__item users-segment__item--static">
    <span><i className="icon user"/>&nbsp;Oled selle haru ainus liige.</span>
    <Button
      size="mini"
      color="blue"
      onClick={openImportModal}
      disabled={branchLoading || removedUsers.length > 0}
    >
      <i className="icon users"/>
      Lisa kasutajaid
    </Button>
  </Segment>
);

export default SingleUserSegment;
