import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className='contact'>
      <div className='ui stackable grid container centered'>
        <div className='twelve wide column'>
          <div
            dangerouslySetInnerHTML={{
              __html: t('admin.ContactInfo1'),
            }}
          />
        </div>
        <div className='four wide column'>
          <div
            dangerouslySetInnerHTML={{
              __html: t('admin.ContactInfo2'),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
