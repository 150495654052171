import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import TemplateDeadlinesSegment from '../../Segment/TemplateDeadlinesSegment';
import AddCourseSegment from '../ImportUsersModal/AddCourseSegment';
import './style.css';

const templateFlowSteps = ['NAME', 'COURSES', 'DEADLINES'];

const CreateTemplateModal = ({
  user,
  step,
  isModalOpen,
  setIsModalOpen,
  createTemplate,
  isLoading,
}) => {
  const [categories, setCategories] = useState([]);
  const [currentStep, setCurrentStep] = useState(templateFlowSteps[0]);
  const [templateName, setTemplateName] = useState('');
  const [selectedCourses, setSelectedCourses] = useState({});

  useEffect(() => {
    setCurrentStep(step);
    fetch(`/api/v1/courses/branch/${user.branch.id}`)
      .then((response) => response.json())
      .then((response) => {
        setCategories(response);
      });
  }, [step]);

  const handleChange = (e, { name, value }) => {
    if (name) setTemplateName(value);
  };

  const selectCourses = (courses) => {
    if (courses) {
      const selected = { ...selectedCourses, ...courses };
      setSelectedCourses(selected);
    }
  };

  const deselectCourses = (courseIds) => {
    if (courseIds) {
      const selected = { ...selectedCourses };
      courseIds.forEach((courseId) => {
        if (Object.keys(selected).includes(courseId)) {
          delete selected[courseId];
        }
      });
      setSelectedCourses(selected);
    }
  };

  let content;
  switch (currentStep) {
    case 'COURSES': {
      content = (
        <div>
          Vali, millised moodulid soovid õpirajale lisada
          <br />
          {categories &&
            categories.map((category) => (
              <AddCourseSegment
                category={category}
                selectedCourses={selectedCourses}
                selectCourses={selectCourses}
                deselectCourses={deselectCourses}
              />
            ))}
        </div>
      );
      break;
    }
    case 'DEADLINES': {
      content = (
        <div>
          Määra moodulite läbimiseks tähtajad. Kõik intervallid loetakse alates
          kasutaja loomisest. Tähtajatu mooduli lisamiseks jäta lahter tühjaks.
          <br />
          <TemplateDeadlinesSegment
            selectedCourses={selectedCourses}
            selectCourses={selectCourses}
            categories={categories}
          />
        </div>
      );
      break;
    }
    case 'NAME':
    default: {
      content = (
        <div>
          <Form.Input
            onChange={handleChange}
            name='templateName'
            placeholder='Õpiraja nimi'
            value={templateName}
          />
        </div>
      );
      break;
    }
  }

  const isLastStep =
    templateFlowSteps.indexOf(currentStep) === templateFlowSteps.length - 1;

  const onNext = () => {
    if (!isLastStep) {
      setCurrentStep(
        templateFlowSteps[templateFlowSteps.indexOf(currentStep) + 1],
      );
    } else {
      createTemplate(templateName, selectedCourses);
    }
  };

  return (
    <div className='template-modal'>
      <Button onClick={() => setIsModalOpen(!isModalOpen)}>
        <i className='icon plus' /> Lisa õpirada
      </Button>
      <Modal
        dimmer='inverted'
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Modal.Header>Uus õpirada</Modal.Header>
        <Modal.Content>{content}</Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsModalOpen(false)}>Sulge</Button>
          <Button loading={isLoading} onClick={onNext} color='blue'>
            {!isLastStep ? 'Edasi' : 'Kinnita'}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default CreateTemplateModal;
