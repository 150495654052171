import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import { Divider } from 'semantic-ui-react';
import AddCourseModal from '../../components/Modal/AddCourseModal';
import CategorySegment from '../../components/Segment/CategorySegment';

const Courses = ({
                   user,
                   getCourseDetails,
                   courseLoading,
                   joinCourse,
                 }) => {
  const [categories, setCategories] = useState([]);
  const [modalCategory, setModalCategory] = useState(null);
  const getCourses = () => {
    fetch(`/api/v1/courses/branch/${user.branch.id}`)
    .then((response) => response.json())
    .then((response) => setCategories(response));
  };
  useEffect(() => {
    if (user && !courseLoading) {
      getCourses();
    }
  }, [courseLoading]);
  return (
    <div className="courses">
      {categories.map((category) =>
        <Fragment key={`category–${category.id}`}>
          <CategorySegment
            key={`category-${category.id}`}
            user={user}
            category={category}
            courseLoading={courseLoading}
            getCourseDetails={getCourseDetails}
            setModalCategory={setModalCategory}
            getCourses={getCourses}
            joinCourse={joinCourse}
            showAll
          />
          {category.isEditable && (
            <p className="internal">
              <Divider horizontal inverted>
                Skillaby sisu
              </Divider>
            </p>
          )}
        </Fragment>
      )}
      <AddCourseModal
        closeModal={() => setModalCategory(null)}
        categoryId={modalCategory}
        user={user}
      />
    </div>
  );
};

export default Courses;
