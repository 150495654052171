import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import Terms from '../../../pages/Terms';

const TermsModal = ({ isTermsModalOpen, setIsTermsModalOpen }) => {
  const { t } = useTranslation();

  return (
    <Modal
      dimmer='inverted'
      open={isTermsModalOpen}
      onClose={() => setIsTermsModalOpen(false)}
    >
      <Modal.Header>{t('landingPage.RightsTitle')}</Modal.Header>
      <Modal.Content>
        <div className='modal__content'>
          <Terms />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setIsTermsModalOpen(false)}>
          {t('landingPage.CloseBtn')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TermsModal;
