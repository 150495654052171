import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, List, Modal, Popup } from 'semantic-ui-react';
import './style.css';

const CourseModal = ({
  user,
  courseDetails,
  joinCourse,
  leaveCourse,
  setCourseDetails,
  courseLoading,
  setCourseLoading,
}) => {
  const { t } = useTranslation();
  const deleteCourse = (courseId) => {
    setCourseLoading(true);
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ courseId }),
    };
    fetch(`/api/v1/course/${courseId}`, requestOptions)
      .then(() => {
        setCourseDetails(null);
        setCourseLoading(false);
      })
      .catch();
  };
  const getUnitIcon = (type) => {
    if (type) {
      switch (type.toLowerCase()) {
        case 'video':
          return 'video play';
        case 'test':
          return 'pencil';
        default:
          break;
      }
    }
    return 'folder open';
  };
  return (
    <Modal
      dimmer='inverted'
      onClose={() => setCourseDetails(null)}
      open={!!courseDetails}
    >
      <Modal.Header>
        <img
          alt={courseDetails.name}
          src={courseDetails.avatar}
          className='modal__image'
        />
        {courseDetails.name}
      </Modal.Header>
      <Modal.Content>
        <div className='modal__content'>
          <h3>{t('admin.ModuleDesc')}</h3>
          <p style={{ whiteSpace: 'pre-wrap' }}>{courseDetails.description}</p>
          <h3>{t('admin.ModuleSteps')}</h3>
          <List>
            {courseDetails &&
              courseDetails.units &&
              courseDetails.units.map((unit) => (
                <List.Item key={unit.id}>
                  <List.Icon name={getUnitIcon(unit.type)} />
                  <List.Content>
                    {user.courses.find(
                      (userCourse) => userCourse.id === courseDetails.id,
                    ) ? (
                      <a href={unit.url} target='_blank' rel='noreferrer'>
                        {unit.name}
                      </a>
                    ) : (
                      <span>{unit.name}</span>
                    )}
                  </List.Content>
                </List.Item>
              ))}
          </List>
        </div>
      </Modal.Content>
      <Modal.Actions>
        {user.isAdmin && courseDetails.isEditable && (
          <Popup
            style={{ zIndex: 9999999999999 }}
            trigger={
              <Button color='red' loading={courseLoading != null}>
                <i className='icon trash' />
                {t('admin.RemoveModule')}
              </Button>
            }
            content={
              <div style={{ textAlign: 'center' }}>
                {t('admin.SureNotif')}
                <br />
                <br />
                <Button
                  color='red'
                  size='mini'
                  onClick={() => deleteCourse(courseDetails.id)}
                >
                  <i className='icon trash' />
                  {t('admin.RemoveText')}
                </Button>
              </div>
            }
            on='click'
            position='top center'
          />
        )}
        {!courseDetails.isDisabled && (
          <>
            {!user.courses.find(
              (userCourse) => userCourse.id === courseDetails.id,
            ) ? (
              <Button
                color='blue'
                loading={courseLoading != null}
                onClick={() => joinCourse(courseDetails.id)}
              >
                <i className='icon add' />
                {t('admin.AddModule')}
              </Button>
            ) : (
              <Button
                color='orange'
                loading={courseLoading != null}
                onClick={() => leaveCourse(courseDetails.id)}
              >
                <i className='icon remove' />
                {t('admin.DeleteModule')}
              </Button>
            )}
          </>
        )}
        <Button onClick={() => setCourseDetails(null)}>
          {t('landingPage.CloseBtn')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CourseModal;
