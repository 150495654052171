import React from 'react';
import { Icon, Label, Segment } from 'semantic-ui-react';
import './style.css';
import { getUrlPattern } from '../../../helpers/helpers';
import { useTranslation } from 'react-i18next';

const CourseSegment = ({ user, course, getCourseDetails, courseLoading }) => {
  const urlPattern = getUrlPattern(user);
  const { t } = useTranslation();
  const openCourse = (courseId) => {
    window.open(
      `https://${urlPattern}.talentlms.com/learner/course/id:${courseId}`,
      '_blank',
    );
  };

  let statusColor = course.isCompleted ? 'green' : 'gray';
  const deadline = course.deadline && new Date(course.deadline);
  if (deadline && deadline < new Date()) {
    statusColor = 'red';
  }
  const deadlineStr =
    deadline && deadline.toLocaleDateString().replaceAll('/', '.');

  return (
    <Segment
      onClick={() =>
        getCourseDetails &&
        getCourseDetails(course.id, course.isDisabled, false)
      }
      className={`course-segment course-segment--${statusColor}`}
    >
      <span className='course-segment__title'>
        <div className='course-segment__item-main'>
          <div className='course-segment__item-logo'>
            {courseLoading === course.id ? (
              <div className='ui active inline loader tiny' />
            ) : (
              <Icon
                name={
                  course.isCompleted ? 'check circle outline' : 'circle outline'
                }
                color={course.isCompleted ? 'green' : 'grey'}
              />
            )}
          </div>
          {course.name}
        </div>
        <div className='course-segment__item-menu'>
          {getCourseDetails && (
            <span
              className='link'
              onClick={() =>
                getCourseDetails(course.id, course.isDisabled, false)
              }
            >
              <i className='info circle icon' />
              {t('admin.InfoText')}
            </span>
          )}
          {getCourseDetails && !course.isDisabled && (
            <span
              className={course.isDisabled ? 'disabled' : 'link'}
              onClick={(e) => {
                e.stopPropagation();
                if (!course.isDisabled) openCourse(course.id);
              }}
            >
              <i className='arrow alternate circle right icon' />
              <span
                dangerouslySetInnerHTML={{
                  __html: t('admin.CurseLinkBtn'),
                }}
              />
            </span>
          )}
          <Label
            style={{
              visibility: deadline ? 'visible' : 'hidden',
              width: '85px',
              margin: '0 12px',
              textAlign: 'center',
            }}
            color={statusColor}
          >
            {deadlineStr}
          </Label>
        </div>
      </span>
    </Segment>
  );
};

export default CourseSegment;
