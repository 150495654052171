import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import readXlsxFile from 'read-excel-file';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Radio,
  Segment,
  Select,
} from 'semantic-ui-react';
import { formatDate, formatDateForApi } from '../../../helpers/helpers';
import DateSelect from '../../Core/Calendar';
import CloseModal from '../CloseModal';
import AddCourseSegment from './AddCourseSegment';
import DeadlinesSegment from './DeadlinesSegment';
import './style.css';

const IMPORT_STEP_TYPE = 'importType';
const IMPORT_STEP_BULK = 'bulkUpload';
const IMPORT_STEP_FORM = 'importForm';
const IMPORT_STEP_COURSES = 'courses';
const IMPORT_STEP_DEADLINES = 'deadlines';
const IMPORT_STEP_DEACTIVATION = 'deactivation';
const IMPORT_STEP_PROGRESS = 'progress';

const ImportUsersModal = ({
  branch,
  isOpen,
  setIsOpen,
  setBranchLoading,
  categories,
}) => {
  const [step, setStep] = React.useState(IMPORT_STEP_TYPE);
  const [importType, setImportType] = React.useState(null);
  const [newUsers, setNewUsers] = React.useState([]);
  const [selectedCourses, setSelectedCourses] = React.useState({});
  const [deactivationDate, setDeactivationDate] = React.useState(null);
  const [calendarActive, setCalendarActive] = React.useState(false);
  const [templates, setTemplates] = React.useState();
  const [selectedTemplate, setSelectedTemplate] = React.useState();
  const [templateNameList, setTemplateNameList] = React.useState({});
  const [selectedTemplateCourses, setSelectedTemplateCourses] = React.useState(
    {},
  );
  const { t } = useTranslation();

  useEffect(() => {
    // get templates from db
    console.log('drawing whole modal');

    fetch(`/api/v1/template/list`)
      .then((response) => response.json())
      .then((response) => {
        console.log('response', response);
        setTemplates(response);
      });
  }, []);

  const close = () => {
    setIsOpen(false);
    if (!newUsers.find((user) => user.loading)) {
      setBranchLoading(false);
    }
  };

  const selectCourses = async (courses) => {
    let selected = {};

    Object.keys(selectedCourses).forEach((courseID) => {
      selected[courseID] = null;
    });

    Object.keys(courses).forEach((course) => {
      selected[course] = null;
    });

    await setSelectedCourses(selected);
  };

  const deselectCourses = (courseIds) => {
    if (courseIds) {
      const selected = { ...selectedCourses };
      courseIds.forEach((courseId) => {
        if (Object.keys(selected).includes(courseId)) {
          delete selected[courseId];
        }
      });
      setSelectedCourses(selected);
    }
  };

  const addUser = (event) => {
    event.preventDefault();
    setNewUsers(([...newUsers]) => {
      return [
        ...newUsers,
        {
          id: newUsers.length,
          email: '',
          firstName: '',
          lastName: '',
          branchId: branch.id,
          branchName: branch.name,
        },
      ];
    });
  };

  const getTemplateCourses = (templateID) => {
    // the user selected a new template, thus remove all previously selected options
    Object.keys(selectedCourses).forEach((key) => delete selectedCourses[key]);

    fetch(`/api/v1/template/${templateID}/courses`)
      .then((response) => response.json())
      .then((response) => {
        //console.log("Courses of this template", response)
        setSelectedTemplateCourses(response);
        //console.log("selectedTemplateCourses just changed")

        let emptyObj = {};
        let templateNames = {};
        response.forEach((template) => {
          templateNames[template.name] = true;
          template.courses.forEach((course) => {
            emptyObj[course.id] = null;
          });
        });
        setTemplateNameList(templateNames);
        selectCourses(emptyObj);
      });
  };

  const handleUserChange = (e, { name, value }) => {
    if (name) {
      const split = name.split('-');
      const id = parseInt(split[0]);
      const fieldName = split[1];
      setNewUsers(([...newUsers]) => {
        const user = newUsers.find((user) => user.id === id);
        if (user) {
          user[fieldName] = value;
        }
        return newUsers;
      });
    }
  };

  const removeUser = (id) => {
    setNewUsers(([...newUsers]) => {
      const user = newUsers.find((user) => user.id === id);
      if (user) {
        user.deleted = true;
      }
      return newUsers;
    });
  };

  const handleDateChange = (value) => {
    setDeactivationDate(value);
  };

  function handleSuccess(tempId) {
    setNewUsers(([...newUsers]) => {
      const user = newUsers.find((user) => user.id === tempId);
      if (user) {
        user.loading = false;
        user.completed = true;
      }
      return newUsers;
    });
  }

  const handleSubmit = () => {
    newUsers.forEach((newUser) => {
      setBranchLoading(branch);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
      setNewUsers(([...newUsers]) => {
        const user = newUsers.find((user) => user.id === newUser.id);
        if (user) {
          user.loading = true;
        }
        return newUsers;
      });
      fetch('/api/v1/users', {
        ...requestOptions,
        body: JSON.stringify({ newUser }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res.json();
        })
        .then((createdUser) => {
          const promises = [];
          let userId = createdUser.id;
          /* if (selectedTemplate) {
            console.log("======================= option A runs!")
            fetch(`/api/v1/template/${selectedTemplate}/user/${userId}`, {
                ...requestOptions,
                method: 'PUT',
              },
            ).then((a) => console.log(a)).catch((b) => console.log(b));
          } else if (Object.keys(selectedCourses).length > 0) { */
          console.log('======================= option B runs!');
          promises.push(
            Object.keys(selectedCourses).map((courseId) => {
              const courseBody = { pathId: createdUser.pathId };
              if (!!selectedCourses[courseId]) {
                courseBody.deadline = formatDateForApi(
                  selectedCourses[courseId],
                );
              }
              return fetch(`/api/v1/course/${courseId}/user/${userId}`, {
                ...requestOptions,
                body: JSON.stringify(courseBody),
              });
            }),
          );
          /* } */
          if (!!deactivationDate) {
            promises.push(
              fetch(
                `/api/v1/users/${userId}/deactivation-date?date=${formatDateForApi(
                  deactivationDate,
                )}`,
                { ...requestOptions },
              ),
            );
          }
          if (promises.length > 0) {
            Promise.all(promises).then(() => handleSuccess(createdUser.tempId));
          } else {
            handleSuccess(createdUser.tempId);
          }
        })
        .catch((e) => {
          console.log(e);
          setNewUsers(([...newUsers]) => {
            const user = newUsers.find((user) => user.id === newUser.id);
            if (user) {
              user.loading = false;
              user.error = true;
              user.errorMessage = e;
            }
            return newUsers;
          });
        });
    });
  };

  const readFile = () => {
    const input = document.getElementById('input');
    if (input) {
      readXlsxFile(input.files[0]).then((rows) => {
        let index = 0;
        const imported = [];
        rows.forEach((row) => {
          if (row.length === 3 && row[0].indexOf('@') > -1) {
            imported.push({
              id: index,
              email: row[0],
              firstName: row[1],
              lastName: row[2],
              branchId: branch.id,
              branchName: branch.name,
            });
            index++;
          }
        });
        setNewUsers(imported);
      });
    }
  };

  if (!branch) {
    return null;
  }

  const stepImportTypeContent = (
    <div className='add-users__content'>
      <p>{t('admin.AddingMethod')}</p>
      <Segment placeholder>
        <Grid columns={2} stackable textAlign='center'>
          <Divider vertical className='hide-mobile'>
            {t('landingPage.or')}
          </Divider>
          <Grid.Row verticalAlign='middle'>
            <Grid.Column>
              <Header icon>
                <Icon name='user' />
              </Header>
              <Button
                positive
                type='button'
                onClick={() => {
                  setNewUsers([
                    {
                      id: 0,
                      email: '',
                      firstName: '',
                      lastName: '',
                      branchId: branch.id,
                      branchName: branch.name,
                    },
                  ]);
                  setStep(IMPORT_STEP_FORM);
                  setImportType(IMPORT_STEP_FORM);
                }}
              >
                <h4>{t('admin.FirstMethod')}</h4>
              </Button>
            </Grid.Column>
            <Divider className='hide-desktop'>või</Divider>
            <Grid.Column>
              <Header icon>
                <Icon name='users' />
              </Header>
              <Button
                positive
                type='button'
                onClick={() => {
                  setNewUsers([]);
                  setStep(IMPORT_STEP_BULK);
                  setImportType(IMPORT_STEP_BULK);
                }}
              >
                <h4>{t('admin.SecondMethod')}</h4>
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );

  const stepBulkContent = (
    <div className='add-users__content'>
      <p
        dangerouslySetInnerHTML={{
          __html: t('admin.ImportUsersMsg'),
        }}
      />
      <Input action>
        <input type='file' id='input' />
        <Button color='blue' onClick={readFile}>
          {t('admin.ReadFromFile')}
        </Button>
      </Input>
      {newUsers.length > 0 && (
        <Fragment>
          <br />
          <strong>Failist leitud {newUsers.length} kasutajat.</strong>
        </Fragment>
      )}
    </div>
  );

  const stepFormContent = (
    <Form className='add-users__content'>
      {newUsers
        .filter((user) => !user.deleted)
        .map((newUser) => (
          <div key={`new-${newUser.id}`}>
            <Form.Group className='users-segment__form-row' widths='equal'>
              <Form.Input
                onChange={handleUserChange}
                name={`${newUser.id}-email`}
                placeholder='Email'
                value={newUser.email}
                disabled={newUser.loading}
                error={newUser.error}
                fluid
              />
              <Form.Input
                onChange={handleUserChange}
                name={`${newUser.id}-firstName`}
                placeholder={t('landingPage.formName')}
                value={newUser.firstName}
                disabled={newUser.loading}
                error={newUser.error}
                fluid
              />
              <Form.Input
                onChange={handleUserChange}
                name={`${newUser.id}-lastName`}
                placeholder={t('landingPage.formSurname')}
                value={newUser.lastName}
                disabled={newUser.loading}
                error={newUser.error}
                fluid
              />
              {newUser.deleted}
              <a
                className='add-users-modal__remove'
                onClick={() => removeUser(newUser.id)}
                color='red'
              >
                <i className='close icon' />
              </a>
              <br />
            </Form.Group>
          </div>
        ))}
      <div>
        <Button size='mini' color='blue' onClick={addUser}>
          <i className='icon plus' />
          {t('admin.AddUserBtn')}
        </Button>
      </div>
    </Form>
  );

  const templateOptions = templates && [
    {
      key: 'none',
      value: null,
      text: '–',
    },
    ...templates.map((template) => ({
      key: template.id,
      value: template.id,
      text: template.name,
    })),
  ];
  const stepCoursesContent = (
    <div className='add-users__content'>
      <p>{t('admin.CourseAddingText')}</p>
      <div>
        {/* hide template dropdown UI */}
        {/* <Select
          placeholder={t('admin.CoursePathText')}
          options={templateOptions}
          value={selectedTemplate}
          onChange={async (event, data) => {
            // don't delete "event", semantic-ui is just weird
            const templateID = data.value;

            setSelectedCourses({});
            setSelectedTemplate(templateID);
            await getTemplateCourses(templateID);
            //selectedTemplateCourses
          }}
        /> */}
        <br />
        {categories &&
          categories.map((category) => (
            <AddCourseSegment
              category={category}
              selectedCourses={selectedCourses}
              selectCourses={selectCourses}
              deselectCourses={deselectCourses}
              templateCoursesMatch={selectedTemplateCourses}
              templateNameList={templateNameList}
            />
          ))}
      </div>
    </div>
  );
  const stepDeadlinesContent = (
    <div className='course-deadlines__content'>
      <p>{t('admin.DeadlineMsg')}</p>
      <div>
        <DeadlinesSegment
          categories={categories}
          selectedCourses={selectedCourses}
          selectCourses={selectCourses}
          deselectCourses={deselectCourses}
        />
      </div>
    </div>
  );
  const minDate = new Date();
  minDate.setDate(new Date().getDate() + 1);
  const stepDeactivationContent = (
    <div className='add-users__content'>
      Vali kasutajakontode kehtivuse periood.
      {calendarActive && !!deactivationDate ? (
        <h2>Valitud kuupäev: {formatDate(deactivationDate)}</h2>
      ) : (
        <h2>Kasutajad on tähtajatud</h2>
      )}
      <div className='import-deactivation__actions'>
        <Radio
          toggle
          name='customCourses'
          checked={calendarActive}
          onChange={() => setCalendarActive(!calendarActive)}
          label='Soovin määrata deaktiveerimiskuupäeva.'
        />
      </div>
      <br />
      <div className={!calendarActive ? 'disabled' : undefined}>
        <DateSelect
          minDate={minDate}
          value={deactivationDate}
          onChange={handleDateChange}
        />
      </div>
    </div>
  );

  const importLoading = newUsers.find((user) => user.loading);

  const stepProgressContent = (
    <div className='add-users__content'>
      {!importLoading ? (
        <h4 className='blue-text'>{t('admin.AddSuccessMsg')}</h4>
      ) : (
        <h4 className='orange-text'>{t('admin.AddLoadingMsg')}</h4>
      )}
      <ul className='import-progress'>
        {newUsers
          .filter((usr) => !usr.deleted)
          .map((usr) => {
            let classNames = ['import-progress__item'];
            if (usr.completed)
              classNames.push('import-progress__item--completed');
            if (usr.loading) classNames.push('import-progress__item--loading');
            if (usr.error) classNames.push('error-text');
            return (
              <li className={classNames.join(' ')} key={usr.id}>
                {usr.loading && (
                  <Fragment>
                    <i className='ui active inline loader tiny' />
                    &nbsp;
                  </Fragment>
                )}
                {usr.completed && <i className='icon check' />}
                {usr.error && <i className='icon warning sign' />}
                {usr.email} {usr.firstName} {usr.lastName}
              </li>
            );
          })}
      </ul>
      {newUsers.find((usr) => usr.error) && (
        <b className='error-text'>{t('admin.AddErrorMsg')}</b>
      )}
    </div>
  );

  let content = stepImportTypeContent;
  let next;
  let back;
  let nextText = t('admin.Next');
  let disabled = false;
  switch (step) {
    case IMPORT_STEP_BULK: {
      content = stepBulkContent;
      next = () => setStep(IMPORT_STEP_COURSES);
      back = () => setStep(IMPORT_STEP_TYPE);
      disabled = newUsers.length === 0;
      break;
    }
    case IMPORT_STEP_FORM: {
      content = stepFormContent;
      next = () => setStep(IMPORT_STEP_COURSES);
      back = () => setStep(IMPORT_STEP_TYPE);
      disabled =
        newUsers.length === 0 ||
        !!newUsers.find(
          (usr) =>
            !usr.deleted &&
            (usr.email.length === 0 ||
              usr.firstName.length === 0 ||
              usr.lastName.length === 0),
        );
      break;
    }
    case IMPORT_STEP_COURSES: {
      content = stepCoursesContent;
      next = () =>
        /* !!selectedTemplate ? ()=>{setStep(IMPORT_STEP_PROGRESS);
          handleSubmit();} : */ setStep(IMPORT_STEP_DEADLINES);
      back = () =>
        importType ? setStep(importType) : setStep(IMPORT_STEP_TYPE);
      break;
    }
    case IMPORT_STEP_DEADLINES: {
      content = stepDeadlinesContent;
      next = () => {
        setStep(IMPORT_STEP_PROGRESS);
        handleSubmit();
      };
      back = () => setStep(IMPORT_STEP_COURSES);
      break;
    }
    case IMPORT_STEP_DEACTIVATION: {
      content = stepDeactivationContent;
      next = () => {
        setStep(IMPORT_STEP_PROGRESS);
        handleSubmit();
      };
      back = () => setStep(IMPORT_STEP_COURSES);
      nextText = 'Lisa kasutajad';
      break;
    }
    case IMPORT_STEP_PROGRESS: {
      content = stepProgressContent;
      next = close;
      back = null;
      nextText = t('admin.CloseWindow');
      break;
    }
    default: {
      next = null;
      back = null;
      break;
    }
  }

  return (
    <Modal dimmer='inverted' open={isOpen} onClose={close}>
      <Modal.Header>
        {t('admin.AddingBranchUser')} {branch.name}
        <CloseModal close={close} />
      </Modal.Header>
      <Modal.Content className='add-users'>{content}</Modal.Content>
      {([IMPORT_STEP_TYPE, IMPORT_STEP_PROGRESS].indexOf(step) < 0 ||
        !importLoading) && (
        <Modal.Actions>
          {back && <Button onClick={back}>{t('admin.Back')}</Button>}
          {next && (
            <Button color='orange' disabled={disabled} onClick={next}>
              {nextText}
            </Button>
          )}
        </Modal.Actions>
      )}
    </Modal>
  );
};
export default ImportUsersModal;
