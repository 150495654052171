import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import './style.css';

const Footer = () => {
  const [isMyfitness, setIsMyfitness] = React.useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname === '/myfitness/') {
      setIsMyfitness(true);
    } else {
      setIsMyfitness(false);
    }

    if (pathname === '/login') {
      setIsMyfitness(false);
    }
  }, [location.pathname]);

  return (
    <>
      {isMyfitness ? (
        <footer className='footer'>
          <div className='container'>
            <div className='footer__content'>
              <div className='footer__content-item'>
                <a
                  href='https://www.bcskoolitus.ee/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <p className='myfitness-text'>Myfitness</p>
                </a>
              </div>
              <div className='footer__content-item myfitness-text'>
                <p className='myfitness-terms'>
                  Privaatsus- ja kasutajatingimused
                </p>
                <p className='myfitness-rights'>©2023 FITNESS ACADEMY</p>
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer className='footer'>
          <div className='container'>
            <div className='footer__content'>
              <div className='footer__content-item'>
                <a
                  href='https://www.bcskoolitus.ee/'
                  target='_blank'
                  rel='noreferrer'
                >
                  {new Date().getFullYear()} BCS Koolitus AS
                </a>
              </div>
              <div className='footer__content-item'>
                <Link to='/tingimused'>
                  <a
                    href='https://www.bcskoolitus.ee/privaatsus-ja-kasutustingimused/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('landingPage.RightsLink')}
                  </a>
                </Link>
              </div>
              <div className='footer__content-item'>
                <a
                  href='https://www.facebook.com/balticcomputersystems'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Icon name='facebook square' size='large' />
                </a>
                <a
                  href='https://www.linkedin.com/company/bcskoolitus/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Icon name='linkedin' size='large' />
                </a>
                <a
                  href='https://goo.gl/maps/Zfkkcjt2a6sezXYM6'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Icon name='point' size='large' />
                </a>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
