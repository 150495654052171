import React, { Fragment, useEffect } from "react";
import { Segment } from 'semantic-ui-react';
import "./style.css";
import Cookies from 'universal-cookie';
import UserProgressModal from '../../components/Modal/UserProgressModal';
import SetUserDeactivationModal from '../../components/Modal/SetUserDeactivationModal';
import ImportUsersModal from '../../components/Modal/ImportUsersModal';
import TermsModal from '../../components/Modal/TermsModal';
import UsersTitleSegment from '../../components/Segment/Users/UsersTitleSegment';
import SingleUserSegment from '../../components/Segment/Users/SingleUserSegment';
import UserRowSegment from '../../components/Segment/Users/UserRowSegment';
import UserActionsSegment from '../../components/Segment/Users/UserActionsSegment';

const Users = ({ user, verifyUser }) => {
  const [categories, setCategories] = React.useState();
  const [currentBranch, setCurrentBranch] = React.useState({ ...user.branch });
  const [branchLoading, setBranchLoading] = React.useState();
  const [branchUsers, setBranchUsers] = React.useState();
  const [removedUsers, setRemovedUsers] = React.useState([]);
  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState(false);
  const [deactivationUser, setDeactivationUser] = React.useState();
  const [progressUser, setProgressUser] = React.useState();

  useEffect(() => {
    getBranchUsers();
  }, [currentBranch]);

  const getBranchUsers = () => {
    setBranchLoading(true);
    fetch(`/api/v1/users/branch/${currentBranch.id}`)
    .then((response) => response.json())
    .then((response) => {
      setBranchUsers(response);
      setBranchLoading(false);
    })
  };

  const getBranchCourses = () => {
    fetch(`/api/v1/courses/branch/${currentBranch.id}`)
    .then((response) => response.json())
    .then((response) => {
      setCategories(response);
    })
  };

  const switchBranch = (branch) => {
    const cookies = new Cookies();
    cookies.set(`branch`, branch.name, { path: '/' });
    setCurrentBranch(branch);
    verifyUser();
  }

  const openImportModal = () => {
    if (!categories) {
      getBranchCourses();
    }
    setIsImportModalOpen(true);
  };

  const toggleSelectAll = () => {
    setRemovedUsers(([...removedUsers]) => {
      if (removedUsers.length > 0) {
        return [];
      } else {
        return branchUsers
        .filter((branchUser) => branchUser.id !== user.id)
        .map((branchUser) => branchUser.id);
      }
    });
  };

  const toggleUserRemove = (userId) => {
    setRemovedUsers(([...removedUsers]) => {
      if (removedUsers.indexOf(userId) > -1) {
        removedUsers.splice(removedUsers.indexOf(userId), 1);
      } else {
        removedUsers.push(userId);
      }
      return removedUsers;
    });
  };

  const removeUsers = () => {
    removedUsers.forEach((userId) => removeUser(userId));
    setRemovedUsers([]);
  };

  const removeUser = (userId) => {
    if (userId) {
      setBranchLoading(true);
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
      };
      fetch(`/api/v1/users/${userId}/branch/${currentBranch.id}`, requestOptions)
      .then(() => {
        getBranchUsers(currentBranch.id);
        if (removedUsers.indexOf(userId) > -1) {
          removedUsers.splice(removedUsers.indexOf(userId), 1);
        }
        setBranchLoading(false);
      })
      .catch(() => {
        setBranchLoading(false);
      });
    }
  };

  return (
    <div className="users">
      <Segment.Group key={`branch-${currentBranch.id}`} className="users-segment">
        <UsersTitleSegment
          branchLoading={branchLoading}
          currentBranch={currentBranch}
          user={user}
          switchBranch={switchBranch}
        />
        {branchUsers && (
          <Fragment>
            {branchUsers.length === 1 && (
              <SingleUserSegment
                openImportModal={openImportModal}
                branchLoading={branchLoading}
                removedUsers={removedUsers}
              />
            )}
            {branchUsers.length > 1 && (
              <UserActionsSegment
                toggleSelectAll={toggleSelectAll}
                currentBranch={currentBranch}
                openImportModal={openImportModal}
                removeUsers={removeUsers}
                checked={removedUsers.length === branchUsers.length - 1}
                loading={branchLoading === currentBranch.id}
                selected={removedUsers.length > 0}
              />
            )}
            {branchUsers.map((branchUser) => (
              <UserRowSegment
                user={user}
                branchUser={branchUser}
                currentBranch={currentBranch}
                branchLoading={branchLoading}
                removeUser={removeUser}
                removedUsers={removedUsers}
                toggleUserRemove={toggleUserRemove}
                setProgressUser={setProgressUser}
                setDeactivationUser={setDeactivationUser}
              />
            ))}
          </Fragment>
        )}
      </Segment.Group>
      {!!progressUser && (
        <UserProgressModal
          progressUser={progressUser}
          closeModal={() => {
            setProgressUser(null);
          }}
        />
      )}
      {!!deactivationUser && (
        <SetUserDeactivationModal
          isDisabled={!user.isSuperAdmin}
          deactivationUser={deactivationUser}
          closeModal={() => {
            getBranchUsers(currentBranch.id);
            setDeactivationUser(null);
          }}
        />
      )}
      {isImportModalOpen && (
        <ImportUsersModal
          branch={currentBranch}
          isOpen={isImportModalOpen}
          setIsOpen={setIsImportModalOpen}
          setBranchLoading={setBranchLoading}
          categories={categories}
        />
      )}
      <TermsModal
        isTermsModalOpen={isTermsModalOpen}
        setIsTermsModalOpen={setIsTermsModalOpen}
      />
    </div>
  );
};

export default Users;
