export const pricingUserSteps = [{
  max: 1,
}, {
  min: 2,
  max: 4,
}, {
  min: 5,
  max: 10,
}, {
  min: 11,
  max: 25,
}, {
  min: 26,
  max: 50,
}, {
  min: 51,
  max: 100,
}, {
  min: 101,
  max: 200,
}, {
  min: 201,
}];
export const pricingDurationSteps = [3, 6, 12];
export const pack3PricingTiersYearly = [30, 110, 250, 575, 1100, 1990, 3790, 5490];
export const pack6PricingTiersYearly = [50, 190, 465, 1090, 2090, 3990, 7490, 10490];
export const pack12PricingTiersYearly = [65, 345, 575, 1390, 2590, 4490, 8990, 12990];

export const formatDate = (date) => {
  if (date) {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return dd + '/' + mm + '/' + yyyy;
  }
  return null;
}

export const formatDateForApi = (date) => {
  if (date) {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return dd + '-' + mm + '-' + yyyy;
  }
  return null;
}

export const formatTalentDate = (dateStr) => {
  if (!dateStr || dateStr.length < 1) return null;
  const split = dateStr.split(',')[0].split('/');
  const day = split[0];
  const month = split[1];
  const year = split[2];
  return new Date(year,month-1, day);
}

export const getUrlPattern = (user) => {
  let urlPattern = "skillaby";
  const { branch } = user;
  if (branch) {
    const branchName = branch.name;
    return `${branchName}-skillaby`;
  }
  return urlPattern;
};
