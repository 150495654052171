import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
const LandingModal = ({ category, close }) => {
  const { t } = useTranslation();
  const isOpen =
    !!category &&
    ((category.courses && category.courses.length > 0) ||
      !!category.customInfo);
  return (
    <Modal dimmer='inverted' open={isOpen} onClose={close}>
      <Modal.Header>
        {category.courses ? (
          <img
            alt={category.name}
            src={category.icon}
            className='modal__image'
          />
        ) : (
          <img
            alt={category.name}
            src='skillaby_S_wo.png'
            className='modal__image'
          />
        )}
        {category.name}
      </Modal.Header>
      <Modal.Content>
        {category.courses ? (
          <div className='modal__content'>
            {t('landingPage.ModulesTitle')}
            <ul>
              {category.courses
                .sort((a, b) => a.id - b.id)
                .map((course) => (
                  <li key={`course-${course.id}`}>{course.name}</li>
                ))}
            </ul>
          </div>
        ) : (
          <div className='modal__content'>{category.customInfo}</div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>{t('landingPage.CloseBtn')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LandingModal;
