import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import './style.css';
import Terms from '../../../pages/Terms';

const AgreementModal = ({ uuid, toggleModal, isOpen }) => {
  const setAgreementCookie = (uuid, cookieValue) => {
    const cookies = new Cookies();
    cookies.set(`agreement-${uuid}`, cookieValue, { path: '/' });
    toggleModal(false);
    window.location.reload(false);
  };
  const { t } = useTranslation();

  const decline = () => {
    if (window && window.location && window.location.href) {
      window.location = window.location.href.split('?')[0];
    } else {
      window.location = 'https://skillaby.bcskoolitus.ee';
    }
  };
  return (
    <Modal dimmer='inverted' open={isOpen} onClose={() => toggleModal(false)}>
      <Modal.Header>{t('admin.UserTermsTitle')}</Modal.Header>
      <Modal.Content>
        <div className='modal__content'>
          <Terms />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color='orange' onClick={() => setAgreementCookie(uuid, true)}>
          {t('admin.UserTermsAgree')}
        </Button>
        <Button onClick={decline}>{t('admin.UserTermsDisagree')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AgreementModal;
