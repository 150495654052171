export const estTranslation = {
  landingPage: {
    title:
      'MEIE USUME, ET <span class="white-text">DIGITAALNE</span> SISSEELAMINE ON <span class="white-text">TULEVIK</span>.',
    subtitle:
      'Skillaby on veebiplatvorm personalitöötajale, mis aitab lihtsustada, automatiseerida ning muuta läbipaistvamaks uute töötajate sisseelamist.',
    userRegBtn: 'PROOVI <span style="color: #e5422d;">SKILLABYD</span> TASUTA',
    userRegTitle: 'Millist demokontot soovid luua?',
    user: 'Õppija',
    users: 'Personalihaldur',
    userSubTitle:
      'Vali õppija demo, kui oled eraisik ning soovid näha ainult õpikeskkonda.',
    usersSubTitle:
      'Vali halduri demo, kui oled ettevõtte esindaja ja tahad näha ka personali halduskeskkonda. Õppija vaade on Sulle samuti nähtav.',
    userBtn: 'Vali',
    managerBtn: 'Valitud',
    or: 'või',
    formName: 'Eesnimi',
    formSurname: 'Perenimi',
    formCompany: 'Ettevõte',
    emailAgreed: 'Nõustun e-mailide saamisega',
    Agreed: 'Nõustun kasutustingimustega',
    RegBtn: 'Tellin demokeskkonna',
    thankMsg:
      'Aitäh! Demokeskkond on loomisel ning saadame peagi täpsema info e-mailile.',
    AccMainTitle: 'Mida Skillabyga teha saab',
    AccTitle1: 'Personaalsed õpirajad',
    AccDesc1: `
			<p>
				<strong>Probleem</strong>: Uus töötaja on infoga ülekoormatud. Temale vajalikud infomaterjalid on 
				mitmes erinevas kohas laiali ning hajutatult teiste dokumentide vahel. Tal puudub 
				ülevaade, kuidas ta oma sisseelamisega edeneb.
			</p>
			<p>
				<strong>Lahendus:</strong> personalispetsialist saab luua igale töötajale õpiraja, mis summutab 
				üleliigse infomüra. Personaalsel õpirajal on töötajale näha ainult need materjalid, 
				mis on talle olulised. Need on koondatud ühte kohta, järjestatud ning tähtajastatud. 
				See annab töötajale selguse oma progressi osas, näitab talle milline samm on järgmine 
				ning samuti lubab tal oma aega paremini planeerida.
			</p>
			<p>
				Õpirada jääb töötajale alles. Nii saab alati materjalide poole tagasi pöörduda kui ta
				tunneb vajadust oma mälu turgutada. 
			</p>
		`,
    AccTitle2: 'Raportid ja <i>compliance</i> haldus',
    AccDesc2: `
		<p>
		<strong>Probleem</strong>: Personalispetsialistil puudub ülevaade
		sellest, kuidas töötaja sisseelamine edeneb. Ta muretseb, kas kõik
		kriitilise tähtsusega tegevused, näiteks tööohutusjuhendiga
		tutvumine, on ikka tehtud. Kas uus töötaja on sisseelamisega järje
		peal? Kas midagi olulist on kahe silma vahele jäänud?
	</p>
	<p>
		<strong>Lahendus:</strong> Töötaja edenemine oma õpirajal on
		jooksvalt nähtav ka personalispetsialistile. Kuna sammud on
		tähtajastatud, on niimoodi võimalik näha, kas töötaja on oma
		sisseelamisega järje peal või mitte.
	</p>
	<p>
		Ülevaade läbitud materjalide kohta, näiteks töötaja kinnitus, et
		on tutvunud tööohutusjuhendiga, salvestub Skillabysse ning on
		hõlpsasti kättesaadav kirjalikku taasesitamist võimaldavas vormis
		(<i>compliance</i> haldus)
	</p>
		`,
    AccTitle3: 'Testid',
    AccDesc3: `
		<p>
				<strong>Probleem:</strong> Kas töötaja on päriselt
				infomaterjalides sisalduva omandanud või need lihtsalt läbi
				klõpsinud?
			</p>
			<p>
				<strong>Lahendus:</strong> Õppematerjalide järele (või ka ette ja
				vahele) saab luua teste veendumaks, et töötaja on info omandanud.
				Teste on võimalik luua erinevates formaatides ning testiküsimusi
				saab roteerida, et iga kord oleks kuvatud testis erinevad
				küsimused (välistab katse-eksituse meetodiga testi läbimise).
			</p>
			<p>
				Kui töötaja ei soorita testi vajalikule tasemele, on võimalik ta
				suunata tagasi materjalide juurde. Personalispetsialist saab
				vaadata kõiki testi soorituskatseid ja tulemusi.
			</p>
		`,
    AccTitle4: 'Õppematerjalide disain',
    AccDesc4: `
		<p>
				<strong>Probleem:</strong> (a) Sisseelamiseks vajalikud
				infomaterjalid on üksluised. Töötajate tähelepanu info omandamisel
				hajub ning sisseelamine on seetõttu igav ja väsitav. Õpitu ei jää
				meelde. (b) Sisseelamise materjalid digitaalsel kujul puuduvad
				(kõik on välja prinditud või näost-näkku koolituse kujul)
			</p>
			<p>
				<strong>Lahendus:</strong> Lisaks platvormile pakub Skillaby
				õpidisaini teenust, kus sisseelamise loome ümber formaati, mis on
				mõeldud iseseisvaks e-õppeks. Nii on uuele töötajale tänu
				atraktiivsetele ja kaasavatele õppematerjalidele sisseelamine
				tulemuslikum ja ka põnevam.
			</p>
			<p>
				<a href='https://skillaby.talentlms.com/shared/start/key:ZGSDNHRK'>
					Vaata Skillaby õpidisaini näidismaterjali
				</a>
			</p>
		`,
    CourseTitle: 'Olemasolevad kursused',
    RightsLink: 'Privaatsus- ja kasutustingimused',
    RightsTitle: 'Skillaby kasutustingimused',
    CloseBtn: 'Sulge',
    Terms: `<p>
		<strong>Skillaby kasutustingimused</strong>
	</p>
	<br />
	<p>Kasutustingimused kehtivad alates 01.05.2023</p>
	<br />
	<p>
		Kasutustingimused reguleerivad digioskuste e-õppe teenust, mida BCS
		Koolitus pakub Skillaby keskkonna kaudu ning mille eesmärk on aidata
		Kliendil lihtsamalt hallata isikute koolitamist ning aidata
		mugavamalt planeerida ja teostada isikute ametialast arengut.
	</p>
	<h3>1. Mõisted</h3>
	<ul>
		<li>
			<strong>BCS Koolitus -&nbsp;</strong>BCS Koolitus AS (registrikood
			107230477, aadress Aia 7, Tallinn 10111).
		</li>
		<li>
			<strong>Klient</strong> &ndash; isik, kes on sõlminud BCS
			Koolitusega lepingu digioskuste e-õppe teenuse kasutamiseks.&nbsp;
		</li>
		<li>
			<strong>Skillaby keskkond&nbsp;</strong>&ndash; pilvekeskkonnas
			asuv<strong>&nbsp;</strong>e-õppe teenuse keskkond, mis sisaldab
			digioskuste õpiampsude s.o. video-, heli- ja tekstimaterjalide
			panka; õpitut kinnistavaid interaktiivseid harjutusi; vastavalt
			paketile võimalust hoiustada Kliendi enda õppematerjale; võimalust
			luua õppematerjalidest koosnevaid personaalseid õpiradasid;
			võimalus jälgida õppijate progressi õppematerjalide läbimisel.
		</li>
		<li>
			<strong>Lõppkasutaja</strong> &ndash; isik, kellele Klient annab
			ligipääsu Skillaby keskkonnale.
		</li>
	</ul>
	<h3>2. Digioskuste e-õppe teenus</h3>
	<ul>
		<li>
			Klient saab ligipääsu pilvekeskkonnas asuvale e-õppe teenuse
			keskkonnale nimega Skillaby.
		</li>
		<li>
			Kliendil on õigus anda oma töötajale või muule isikule ligipääs
			Skillaby keskkonnale.
		</li>
		<li>
			Lõppkasutaja saab ligipääsu Kliendi poolt talle loodud õpirajale
			ning selles sisalduvatele õppematerjalidele.&nbsp;
		</li>
		<li>
			Klient saab ligipääsu Lõppkasutajate õpiampsude läbimise infole ja
			õpianal&uuml;&uuml;tikale.&nbsp;
		</li>
		<li>Skillaby keskkond on Kliendile kättesaadav ööpäevaringselt.</li>
		<li>
			BCS Koolitus arendab ja täiendab pidevalt Skillaby keskkonda, sh
			õpiampsude panka eesmärgiga pakkuda Kliendile ja Lõppkasutajale
			järjest kvaliteetsemat ja mitmek&uuml;lgsemat teenust.
		</li>
	</ul>
	<h3>3. Hinnastamine</h3>
	<ul>
		<li>
			Teenuse hinda on võimalik arvutada vastavalt Kliendi vajadusele
			Skillaby hinnakalkulaatoriga, mis on leitav Skillaby kodulehel (
			<a href='https://skillaby.bcskoolitus.ee/'>
				https://skillaby.bcskoolitus.ee
			</a>
			/).
		</li>
		<li>
			BCS Koolitus esitab Kliendile arve järgneva perioodi kohta
			hiljemalt uue perioodi alguse kuupäeval
		</li>
	</ul>
	<h3>4. Isikuandmete töötlemine</h3>
	<ul>
		<li>
			Teenusele ligipääsu loomiseks töötleb BCS Koolitus Kliendi ja
			Lõppkasutaja kohta järgmisi isikuandmeid: täisnimi, e-posti
			aadress, telefoni number ja ettevõtte nimi.&nbsp;
		</li>
		<li>
			Teenuse osutamiseks töötleb BCS Koolitus Teenuse osutamise käigus
			tekkivaid andmeid: Lõppkasutajale määratud kursused; läbitud,
			läbimata ning pooleliolevad kursused; Skillabys tehtud testide ja
			harjutuste tulemused; kasutaja viimane sisselogimine; kui tihti
			kasutaja sisse logib; kui kaua kasutaja on platvormil aega
			veetnud.&nbsp;
		</li>
		<li>
			Teenuse osutamisel on isikuandmete volitatud töötleja BCS
			Koolitus, <a href='mailto:kool@bcs.ee'>kool@bcs.ee</a>, telefon
			680 6880.
		</li>
		<li>
			Isikuandmeid säilitatakse kuni kuu aega pärast kasutaja
			ligipääsukonto sulgemiseni, kui õigusaktidest ei tulene teisiti.
		</li>
		<li>
			BCS Koolitus võib avaldada isikuandmeid BCS Koolitusele teenust
			pakkuvatele kolmandatele isikutele, kellega BCS Koolitus on
			sõlminud lepingu ja kes on kohustatud tagama nõuetekohased
			kaitsemeetmed isikuandmete töötlemisel. BCS Koolitus esitab
			isikuandmed Skillaby keskkonna platvormi teenusepakkujale.
		</li>
		<li>
			BCS Koolitus tagab vastavalt kehtivates õigusaktides sätestatud
			nõuetele, et isikuandmeid kaitstakse asjakohaste tehniliste ja
			organisatsiooniliste meetmetega vältimaks loata juurdepääsu,
			ebaseaduslikku töötlemist või avalikustamist, juhuslikku kadu,
			muutmist või hävitamist. BCS Koolitus rakendab
			organisatsioonilisi, f&uuml;&uuml;silisi, IT- ja muid vajalikke
			turvameetmeid, et tagada efektiivne isikuandmete kaitse.&nbsp;
		</li>
		<li>
			Teenuse kasutamise privaatsus- ja kasutustingimused on toodud BCS
			Koolituse AS kodulehel: skillaby.bcskoolitus.ee/tingimused Klient
			kinnitab Teenuse kasutamise alustamisega, et on tutvunud ja
			nõustub eelmainitud tingimustega.&nbsp;
		</li>
	</ul>
	<h3>5. Intellektuaalomand</h3>
	<ul>
		<li>
			Teenuse osad ning nendes sisalduvad õppematerjalid on BCS Koolitus
			või teenuse pakkumisega seotud kolmandate osapoolte
			intellektuaalomand ning neile kuuluvad kõik sellega seotud
			õigused.
		</li>
		<li>
			Kliendil ja Lõppkasutajal ei ole lubatud ilma BCS Koolitus
			kirjaliku nõusolekuta:&nbsp;
			<ul>
				<li>
					Alla laadida, salvestada või mistahes meetodil väljapool BCS
					Koolituse digioskuste e-õppe keskkonda kättesaadavaks teha
					teisele isikule õpisisu (õppematerjalid, õpikeskkonna osad
					jm), välja arvatud sisu, mida BCS Koolitus on mõelnud
					allalaadimiseks (&uuml;lesandefailid, konspektifailid jm);
				</li>
				<li>
					m&uuml;&uuml;a, muuta või muul viisil levitada teenuse sisu;
				</li>
				<li>
					kasutada teenust eesmärkidel, mis pole Teenuse kirjelduses
					sätestatud.&nbsp;
				</li>
			</ul>
		</li>
		<li>
			Kui Klient või Lõppkasutaja rikub Lepingu eelmises punktis
			nimetatud kohustust, on BCS Koolitusel õigus nõuda Kliendilt
			leppetrahvi 10&nbsp;000 eurot iga rikkumise kohta.
		</li>
	</ul>
	<h3>6. Kasutustingimuste muutmine</h3>
	<ul>
		<li>
			BCS Koolitus võib kasutustingimusi muuta igal ajal, teavitades
			Teid vähemalt 14 päeva ette. Muudatused jõustuvad 14 päeva pärast
			nende avaldamise hetkel veebiaadressil
			skillaby.bcskoolitus.ee/tingimused. Muudatuste jõustumise täpne
			kuupäev on samal veebilehel eraldi välja toodud. Kui jätkate
			Skillaby keskkonna kasutamist pärast muudatuste jõustumist, siis
			loetakse, et olete muudatustega nõustunud. Kui Te ei nõustu uute
			tingimustega, siis on Teil õigus lõpetada ligipääs Skillaby
			keskkonnale.
		</li>
	</ul>
	<h3>7. Teenuse kasutamise lõpetamine</h3>
	<ul>
		<li>
			Kliendil on õigus igal ajahetkel lõpetada ligipääs teenusele
			teatades teenuse lõpetamise soovist kirjalikult BCS Koolitust.
		</li>
		<li>
			BCS Koolitusel on õigus lõpetada Kliendi ligipääs Skillaby
			keskkonnale, teavitades sellest Klienti kirjalikult 30
			kalendripäeva ette. Vastavalt Poolte kokkuleppele on võimalik
			teenuse kasutamine lõpetada l&uuml;hema etteteatamistähtajaga.
		</li>
	</ul>
		`,
    AddMore: 'Lähiajal on lisandumas veel:',
    ModulesTitle: 'Selle teema raames saad läbida selliseid mooduleid:',
    LoginTitle:
      'Kui Sulle on juba Skillabysse ligipääs loodud, saad siin lehel sisse logida',
    LoginError: 'Vale kasutajanimi või parool',
    LogBtn: 'Õppima!',
    ForgotPswd: 'Unustasid parooli?',
    RecoveryTitle:
      'Sisesta enda andmed ja juhised uue parooli seadistamiseks saadetakse Sinu e-mailile.',
    RecoveryBtn: 'Saada',
  },
  admin: {
    Greeting: 'Tere',
    ForgotPswd: 'Muuda parooli',
    Logout: 'Logi välja',
    CookieConsent: `Teenuse kasutaja kinnitab teenuse kasutamise alustamisega, et on
		teadlik ja järgib intellektuaalomandi kaitse tingimusi ning
		nõustub isikuandmete töötlemise ja kaitse tingimustega.`,
    CookiePrivacyBtn: 'Loe lähemalt.',
    CookieCloseBtn: 'Sain aru',
    UserTermsTitle: 'Lõppkasutaja kasutustingimused',
    UserTermsAgree: 'Nõustun',
    UserTermsDisagree: 'Keeldun',
    Loading: 'Laen...',
    ModuleDesc: 'Mooduli kirjeldus',
    AddModuleText: 'Lisa moodul',
    JoinModuleText: 'Liitu kursusega',
    ModuleSteps: 'Mooduli sammud',
    AddModule: 'Liitu mooduliga',
    DeleteModule: 'Loobu moodulist',
    RemoveModule: 'Kustuta moodul',
    SureNotif: 'Kas oled kindel?',
    RemoveText: 'Kustuta',
    CurseLinkBtn: 'Ava<span className="hide-mobile"> moodul</span>',
    InfoText: 'Info',
    HideModuleBtn: 'Muuda<span className="hide-mobile"> moodulit</span>',
    LearningPath: 'Minu õpirada',
    LearningProgress: 'Õppijate progress!',
    BranchReport: 'Haru raport',
    AddUsers: 'Lisa kasutajaid',
    SureUserNotif:
      'Seda tegevust ei saa tagasi võtta. Kas oled täiesti kindel?',
    Yes: 'Jah',
    SelectAllBtn: 'Vali kõik',
    RemoveSelected: 'Eemalda valitud',
    DownloadReportText: 'Lae alla kasutaja progressi raport',
    RemoveUser: 'Eemalda',
    DownloadBeforeDelete: 'Enne kustutamist lae alla',
    UserReport: 'kasutaja raport',
    AddingBranchUser: 'Kasutajate lisamine harusse ',
    AddingMethod: 'Vali, kuidas soovid kasutajaid lisada:',
    FirstMethod: 'Ükshaaval',
    SecondMethod: 'Failist importides',
    AddUserBtn: 'Lisa',
    Back: 'Tagasi',
    Next: 'Edasi',
    CourseAddingText: `Vali õpirada või kursused, mida soovid lisatavatele kasutajatele
		läbimiseks määrata. Kõik ülejäänud kursused jäävad kasutajale
		vabatahtlikuks läbimiseks.`,
    CoursePathText: 'Õpirada',
    RemoveAll: 'Eemalda kõik',
    AddSuccessMsg: 'Import lõpetatud. Võid akna sulgeda.',
    AddErrorMsg:
      'Mõne kasutaja lisamisel tekkisid probleemid. Palun kontrolli andmeid ja proovi uuesti.',
    AddLoadingMsg: 'Ära sulge veebiakent. Kasutajaid imporditakse.',
    CloseWindow: 'Sulge aken',
    DeadlineMsg: 'Vali tähtajad',
    DeadTimePickerMsg: 'Tähtajatu',
    ReadFromFile: 'Loe failist',
    ImportUsersMsg: `Kasutajate importimiseks <a href='import.xlsx'>lae alla tabelipõhi</a>
		ning lisa vastavad andmed. Seejärel saad faili üles laadida ning
		kasutajate lisamise kinnitada.`,
    ContactInfo1: `<h3>Kas sul on seoses Skillabyga mõni küsimus?</h3>
		<p>Skillaby meeskond on kogu oma nõu ja jõuga Sulle abiks!</p>
		<p>
			Kirjuta meile julgelt aadressil
			<a href='mailto:skillaby@bcs.ee'>skillaby@bcs.ee</a> või helista
			telefonil <a href='tel:+3726806880'>680 68 80</a>.
		</p>`,
    ContactInfo2: `<h3>BCS Koolitus AS</h3>
		<p>
			Aia 7, Tallinn 10111
			<br />
			Telefon 680 68 80
			<br />
			skillaby@bcs.ee
		</p>`,
  },
  PasswordChange: {
    MainTitle: 'Parooli muutmine',
    Loader: 'Tegutsen...',
    FirstLogin: 'Palun muuda esimese sisselogimise järel enda parool.',
    NewPassword: 'Palun sisesta uus parool',
    NewPasswordPlaceholder: 'Uus parool',
    NewPasswordPlaceholderRepeat: 'Korda uut parooli',
    PasswordSuccessMsg: 'Parool muudetud!',
    PasswordErrorMsg: 'Tekkis tõrge, palun proovi uuesti.',
    ChangeBtn: 'Muuda',
    CancelBtn: 'Tühista',
  },
};
