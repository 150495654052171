import React from 'react';
import './style.css';

const CloseModal = ({ close }) => (
  <a className="close-modal" onClick={close}>
    <i className="close icon small"></i>
  </a>
);

export default CloseModal;
