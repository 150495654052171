import React from 'react';
import { Button, Modal, Radio } from 'semantic-ui-react'
import './style.css';
import { formatDate, formatDateForApi } from '../../../helpers/helpers';
import DateSelect from '../../Core/Calendar';

const SetUserDeactivationModal = (
  {
    deactivationUser,
    closeModal,
    isDisabled,
  }) => {
  const [deactivationDate, setDeactivationDate] = React.useState(deactivationUser.current);
  const [loading, setLoading] = React.useState(false);
  const [indefinite, setIndefinite] = React.useState(!deactivationUser.current);

  const postDeactivationDate = () => {
    if (isDisabled) return;
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    };
    let url = `/api/v1/users/${deactivationUser.id}/deactivation-date`;
    if (!indefinite) url = `${url}?date=${formatDateForApi(deactivationDate)}`
    fetch(url, requestOptions)
    .then(() => {
      setLoading(false);
      closeModal();
    });
  };

  const handleDateChange = (value) => {
    setDeactivationDate(value);
  };

  const minDate = new Date();
  minDate.setDate(new Date().getDate() + 1);

  return (
    <Modal
      dimmer="inverted"
      open={!!deactivationUser}
    >
      <Modal.Header>
        Kasutaja deaktiveerimise kuupäeva määramine
      </Modal.Header>
      <Modal.Content>
        <div className="modal__content">
          {!indefinite && !!deactivationDate ?
            (
              <h2>
                Valitud kuupäev: {formatDate(deactivationDate)}
              </h2>
            ) : (
              <h2>
                Kasutajad on tähtajatud
              </h2>
            )}

          <div className="import-deactivation__actions">
            <Radio
              toggle
              name="customCourses"
              checked={!indefinite}
              onChange={() => setIndefinite(!indefinite)}
              label="Soovin määrata deaktiveerimiskuupäeva."
            />
          </div>
          <br/>
          <div className={indefinite ? 'disabled' : undefined}>
            <DateSelect
              minDate={minDate}
              value={deactivationDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>
          Tühista
        </Button>
        <Button
          loading={loading}
          color="blue"
          onClick={postDeactivationDate}
        >
          Salvesta
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SetUserDeactivationModal;
