import React, { useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import {
  getRandomQuote,
  getRandomQuoteEng,
  getRandomQuoteRu,
} from '../../../helpers/quotes';
import AgreementModal from '../../Modal/AgreementModal';
import CourseModal from '../../Modal/CourseModal';
import Footer from '.././Footer';
import Menu from '.././Menu';
import Splash from '../Splash';
import './style.css';

const Page = ({
  user,
  setUser,
  courseDetails,
  joinCourse,
  leaveCourse,
  setCourseDetails,
  courseLoading,
  setCourseLoading,
}) => {
  const [agreementModalOpen, setAgreementModalOpen] = React.useState(false);
  const [talentKey, setTalentKey] = React.useState(null);
  const [currentBranch, setCurrentBranch] = React.useState(null);
  const [isMyfitness, setIsMyfitness] = React.useState(false);
  const { i18n, t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    if (user) {
      setTalentKey(user.loginKey);
    }
    if (user?.branches?.some((branch) => branch.name === 'myfitness')) {
      setCurrentBranch('myfitness');
    } else {
      setCurrentBranch(null);
    }

    const pathname = location.pathname;

    if (pathname === '/myfitness/') {
      setIsMyfitness(true);
    } else {
      setIsMyfitness(false);
    }

    if (pathname === '/login') {
      setIsMyfitness(false);
    }
  }, [user, location.pathname]);
  const currentLng = i18n.language;
  let randomQuote;
  if (currentLng === 'en') {
    randomQuote = getRandomQuoteEng();
  } else if (currentLng === 'ru') {
    randomQuote = getRandomQuoteRu();
  } else {
    randomQuote = getRandomQuote();
  }
  return (
    <>
      {isMyfitness ? (
        <div className='wize-myfitness'>
          <header className='header'>
            <Menu setUser={setUser} user={user} />
          </header>
          {user && (
            <Splash
              quote={randomQuote}
              user={user}
              currentBranch={currentBranch}
            />
          )}
          <div>
            <div className='container'>
              <Outlet />
            </div>
          </div>
          {courseDetails && (
            <CourseModal
              user={user}
              courseDetails={courseDetails}
              joinCourse={joinCourse}
              leaveCourse={leaveCourse}
              setCourseDetails={setCourseDetails}
              courseLoading={courseLoading}
              setCourseLoading={setCourseLoading}
            />
          )}
          <AgreementModal
            isOpen={agreementModalOpen}
            toggleModal={setAgreementModalOpen}
          />
          <Footer />
          <CookieConsent
            buttonClasses='ui button'
            location='bottom'
            buttonText={t('admin.CookieCloseBtn')}
            cookieName={`agreement`}
          >
            <p>
              {t('admin.CookieConsent')}&nbsp;
              <span
                className='link hide-mobile'
                onClick={() => setAgreementModalOpen(true)}
              >
                {t('admin.CookiePrivacyBtn')}
              </span>
              <div className='hide-desktop'>
                <span
                  className='link'
                  onClick={() => setAgreementModalOpen(true)}
                >
                  {t('admin.CookiePrivacyBtn')}
                </span>
              </div>
            </p>
          </CookieConsent>
          {talentKey && (
            <iframe
              style={{ width: 0, height: 0, visibility: 'hidden' }}
              src={talentKey}
              title='description'
            />
          )}
        </div>
      ) : (
        <div
          className={`${
            currentBranch === 'myfitness' ? 'wize-myfitness' : 'wize'
          }`}
        >
          <header className='header'>
            <Menu setUser={setUser} user={user} />
          </header>
          {user && (
            <Splash
              quote={randomQuote}
              user={user}
              currentBranch={currentBranch}
            />
          )}
          <div>
            <div className='container'>
              <Outlet />
            </div>
          </div>
          {courseDetails && (
            <CourseModal
              user={user}
              courseDetails={courseDetails}
              joinCourse={joinCourse}
              leaveCourse={leaveCourse}
              setCourseDetails={setCourseDetails}
              courseLoading={courseLoading}
              setCourseLoading={setCourseLoading}
            />
          )}
          <AgreementModal
            isOpen={agreementModalOpen}
            toggleModal={setAgreementModalOpen}
          />
          <Footer />
          <CookieConsent
            buttonClasses='ui button'
            location='bottom'
            buttonText={t('admin.CookieCloseBtn')}
            cookieName={`agreement`}
          >
            <p>
              {t('admin.CookieConsent')}&nbsp;
              <span
                className='link hide-mobile'
                onClick={() => setAgreementModalOpen(true)}
              >
                {t('admin.CookiePrivacyBtn')}
              </span>
              <div className='hide-desktop'>
                <span
                  className='link'
                  onClick={() => setAgreementModalOpen(true)}
                >
                  {t('admin.CookiePrivacyBtn')}
                </span>
              </div>
            </p>
          </CookieConsent>
          {talentKey && (
            <iframe
              style={{ width: 0, height: 0, visibility: 'hidden' }}
              src={talentKey}
              title='description'
            />
          )}
        </div>
      )}
    </>
  );
};

export default Page;
