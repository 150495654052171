import React from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { getUrlPattern } from '../../../helpers/helpers';
import './style.css';

const AddCourseModal = ({ user, categoryId, closeModal }) => {
  const [courseName, setCourseName] = React.useState('');
  const [courseDescription, setCourseDescription] = React.useState('');
  const [moduleLoading, setModuleLoading] = React.useState(false);

  const handleChange = (e, { name, value }) => {
    if (name === 'courseName') {
      setCourseName(value);
    } else if (name === 'courseDescription') {
      setCourseDescription(value);
    }
  };

  const handleSubmit = () => {
    setModuleLoading(true);
    const courseRequest = {
      courseName,
      courseDescription,
      categoryId,
    };
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(courseRequest),
    };
    fetch('/api/v1/course', requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setModuleLoading(false);
        const urlPattern = getUrlPattern(user);
        window.open(
          `https://${urlPattern}.talentlms.com/trainer/course/id:${response.id}`,
          '_blank',
        );
        window.location.reload(); // TODO: reload only courses
      });
  };

  return (
    <Modal dimmer='inverted' onClose={closeModal} open={!!categoryId}>
      <Modal.Header>Lisa moodul</Modal.Header>
      <Modal.Content>
        <div className='modal__content'>
          <Form onSubmit={handleSubmit}>
            <Form.Input
              onChange={handleChange}
              name='courseName'
              placeholder='Mooduli nimi'
              value={courseName}
            />
            <Form.TextArea
              onChange={handleChange}
              name='courseDescription'
              placeholder='Mooduli kirjeldus'
              value={courseDescription}
            />
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button loading={moduleLoading} color='blue' onClick={handleSubmit}>
          Sisu lisama
        </Button>
        <Button onClick={closeModal}>Sulge</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddCourseModal;
