import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Form, Icon, Message } from 'semantic-ui-react';
import './style.css';

const Authentication = () => {
  const [loginStatus, setLoginStatus] = React.useState(null);
  const [loginFieldError, setLoginFieldError] = React.useState(false);
  const [passwordFieldError, setPasswordFieldError] = React.useState(false);
  const { t } = useTranslation();
  const [credentials, setCredentials] = React.useState({
    login: '',
    password: '',
  });

  const redirectAuthenticatedUser = (isAdmin) => {
    const { pathname, search } = window.location;
    const pathEnd = isAdmin ? 'kasutajad' : 'opirada';
    window.location.href =
      pathname !== '/login'
        ? `${window.location.origin}${pathname}${search}`
        : `${window.location.origin}/${pathEnd}`;
  };

  const handleCredentialsChange = (e, { name, value }) => {
    if (name) {
      setCredentials(({ ...credentials }) => {
        credentials[name] = value;
        if (name === 'login') {
          setLoginFieldError(false);
        }
        if (name === 'password') {
          setPasswordFieldError(false);
        }
        return credentials;
      });
    }
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setLoginStatus('PENDING');
    if (!credentials || !credentials.login || credentials.login.length < 1) {
      setLoginFieldError(true);
    }
    if (
      !credentials ||
      !credentials.password ||
      credentials.password.length < 1
    ) {
      setPasswordFieldError(true);
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials),
    };
    fetch('/api/v1/authentication/login', requestOptions)
      .then((response) => {
        if (response.status > 200) {
          setLoginStatus('INVALID');
          setLoginFieldError(true);
          setPasswordFieldError(true);
        } else {
          const user = response.json();
          redirectAuthenticatedUser(user.isAdmin);
        }
      })
      .catch();
  };

  return (
    <div className='authentication'>
      <div className='ui stackable grid container centered'>
        <div className='six wide column'>
          <div className='authentication-image'>
            <img alt='Skillaby' src='/skillaby_L_bb.png' />
          </div>
        </div>
        <div className='one wide column hide-mobile'>&nbsp;</div>
        <div className='seven wide column'>
          <div className='authentication-text'>
            <p>{t('landingPage.LoginTitle')}</p>
            <Form
              error={loginStatus === 'INVALID'}
              className='authentication-form'
              onSubmit={handleSubmit}
            >
              <Form.Input
                onChange={handleCredentialsChange}
                name='login'
                className='auth-field'
                iconPosition='left'
                size='large'
                icon='user'
                placeholder='E-mail'
                error={loginFieldError}
              />
              <Form.Input
                onChange={handleCredentialsChange}
                name='password'
                type='password'
                className='auth-field'
                iconPosition='left'
                icon='lock'
                size='large'
                placeholder='Parool'
                error={passwordFieldError}
              />
              <Message
                error
                content={
                  <span className='error-text'>
                    <Icon name='exclamation triangle' />
                    {t('landingPage.LoginError')}
                  </span>
                }
              />
              <Button
                loading={loginStatus === 'PENDING'}
                type='submit'
                size='large'
                color='blue'
              >
                {t('landingPage.LogBtn')}
              </Button>
              <Link to='/parool-ununes'>
                <h4>{t('landingPage.ForgotPswd')}</h4>
              </Link>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authentication;
